import {ImageDataLike} from "crop-image-data";

export const imageDataLikeToDataURL = (imageDataLike: ImageDataLike) => {
    const context = Object.assign(document.createElement('canvas'), {
        width: imageDataLike.width,
        height: imageDataLike.height
    }).getContext('2d');

    if (context) {
        const id = context.createImageData(imageDataLike.width, imageDataLike.height);
        for (let i = 0; i < imageDataLike.data.length; i++) {
            id.data[i] = imageDataLike.data[i];
        }

        context.putImageData(id, 0, 0);
        return context.canvas.toDataURL();
    }

    throw new Error("Could not get image data from source.");
};