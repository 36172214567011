import React from "react";
import {BsClock, BsCone} from "react-icons/bs";
import {MdChevronRight, MdKeyboardReturn} from "react-icons/md";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../localization/translationKeys";
import {Exam, ExamStatus} from "../generated/graphql";
import {IoMdCheckmarkCircle} from "react-icons/io";
import {ImFileEmpty} from "react-icons/im";

interface Props {
    exam: Exam;
    onClick?: Function;
    statusHidden?: boolean;
}

const ExamBlock = ({exam, onClick, statusHidden}: Props) => {
    const {t} = useTranslation();

    const buildExamStatusBadge = () => {
        switch (exam.status) {
            case ExamStatus.NoAnswersUploaded:
                return <div
                    className="w-max text-sm rounded gap-2 p-1 px-2 flex items-center font-semibold text-neutral-500 bg-neutral-50">
                    <ImFileEmpty/> {t(`${TranslationKeys.examStatusPrefix}${exam.status}`)}
                </div>;
            case ExamStatus.ReadyForAssessor:
                return <div
                    className="w-max text-sm rounded gap-1.5 p-1 px-2 flex items-center font-semibold text-orange-400 bg-orange-50">
                    <BsCone/> {t(`${TranslationKeys.examStatusPrefix}${exam.status}`)}
                </div>;
            case ExamStatus.BeingAssessed:
                return <div
                    className="w-max text-sm rounded gap-1.5 p-1 px-2 flex items-center font-semibold text-[#14b3d4] bg-[#e7fbff]">
                    <BsClock/> {t(`${TranslationKeys.examStatusPrefix}${exam.status}`)}
                </div>;
            case ExamStatus.IsAssessed:
                return <div
                    className="w-max text-sm rounded gap-1.5 p-1 px-2 flex items-center font-semibold text-[#79b037] bg-[#e8f6d7]">
                    <IoMdCheckmarkCircle/> {t(`${TranslationKeys.examStatusPrefix}${exam.status}`)}
                </div>;
        }
    };

    return (
        <div
            className={`card-shadow rounded p-6 flex justify-between items-center cursor-pointer border border-[#F2F2F2]`}
            onClick={() => onClick && onClick()}>
            <div className="flex flex-col gap-4">
                <h2 className="text-lg font-bold">
                    {t(TranslationKeys.examOf)} {(new Date(exam.createdAt).toLocaleDateString())}
                </h2>

                <div className="text-neutral-500 italic flex items-center gap-2">
                    <MdKeyboardReturn className="-scale-x-100"/>
                    {t(TranslationKeys.paragraphs)} {exam.paragraphs.map((p) => p.abbreviation).join(", ")}
                </div>

                {!statusHidden && buildExamStatusBadge()}
            </div>
            {onClick && <MdChevronRight size={20}/>}
        </div>
    );
};

export default ExamBlock;
