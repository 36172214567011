import React from "react";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";

const NotFoundPage = () => {
    const {t} = useTranslation();

    return <div className="text-center text-white mt-10 mx-auto w-80">
        <h1 className="text-2xl font-bold">{t(TranslationKeys.notFoundTitle)} :(</h1>
        <p className="mt-4">
            {t(TranslationKeys.notFoundDescription)}
        </p>
        <p className="mt-4">
            <a href="/" className="underline">{t(TranslationKeys.notFoundReturnLinkTitle)}</a>
        </p>
    </div>;
};

export default NotFoundPage;