import React from "react";
import {BiCloudUpload} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../localization/translationKeys";
import {FaArrowDown, FaArrowUp, FaTrash} from "react-icons/fa";

interface Props {
    enabled: boolean;
    files: File[];
    setFiles: Function;
    sortingEnabled?: boolean;
    deleteEnabled?: boolean;
    title?: string;
}

const ImageUpload = ({enabled, files, setFiles, sortingEnabled, deleteEnabled, title}: Props) => {
    const {t} = useTranslation();

    const deleteFile = (file: File) => setFiles(files.filter(f => f !== file));

    const moveFileToIndex = (file: File, index: number) => {
        let sortedFiles = [...files.filter(f => f !== file)];
        sortedFiles.splice(index, 0, file);
        setFiles(sortedFiles);
    };

    const moveUp = (file: File) => {
        const currentIndex = files.findIndex(f => f === file);
        if (currentIndex <= -1) return;
        moveFileToIndex(file, currentIndex - 1);
    };

    const moveDown = (file: File) => {
        const currentIndex = files.findIndex(f => f === file);
        if (currentIndex <= -1) return;
        moveFileToIndex(file, currentIndex + 1);
    };

    return (
        <>
            <label className="block w-full">
                <div
                    className="inline-flex justify-center items-center w-full bg-white mt-1 px-3 py-3 border bg-gray-50 rounded">
                    <span className="w-full rounded text-md text-gray-500 text-md">
                        {title ? title : t(TranslationKeys.uploadImagesInfo)}
                    </span>
                    <BiCloudUpload className="text-gray-500"/>
                </div>
                <input
                    disabled={!enabled}
                    type="file"
                    className="w-full hidden"
                    accept="image/*"
                    multiple={true}
                    onChange={(e) => {
                        if (e?.target?.files?.length) {
                            let newFiles = Array.from(e.target.files);
                            setFiles([...files, ...newFiles]);
                        }
                    }}
                />
            </label>
            {files.length > 0 && (
                <div>
                    <hr className="mt-4 mb-4"/>
                    {files.map((f, idx) => {
                        const imgObjectUrl = URL.createObjectURL(f);
                        return (
                            <div key={idx} className={`mb-2 border rounded w-full ${!enabled && 'opacity-50'}`}>
                                <div className="flex justify-between gap-4 text-left h-full">
                                    {sortingEnabled === true && <>
                                        <div className="flex flex-col justify-between">
                                            <div
                                                className={`bg-gray-200 h-full p-2 flex items-center ${idx === 0 ? 'opacity-50' : 'cursor-pointer'}`}
                                                onClick={() => (enabled && idx !== 0) && moveUp(f)}>
                                                <FaArrowUp/>
                                            </div>
                                            <div
                                                className={`bg-gray-200 h-full p-2 flex items-center ${idx === files.length - 1 ? 'opacity-50' : 'cursor-pointer'}`}
                                                onClick={() => (enabled && idx !== files.length - 1) && moveDown(f)}>
                                                <FaArrowDown/>
                                            </div>
                                        </div>
                                    </>}
                                    <div className="break-all h-full p-4">
                                        <img src={imgObjectUrl} className="h-36 p-2" alt="uploaded file"/>
                                    </div>
                                    {deleteEnabled === true && <>
                                        <div className={`bg-red-500 p-2 rounded-r ${enabled && 'cursor-pointer'}`}
                                             onClick={() => enabled && deleteFile(f)}>
                                            <FaTrash size={14} className="text-white mx-1 h-full"/>
                                        </div>
                                    </>}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default ImageUpload;
