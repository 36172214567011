import React from "react";

import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";
import Header from "../../components/Header";
import Body from "../../components/Body";
import SubscriptionPackages from "../../components/SubscriptionPackages";

const RegisterDone = () => {
    const {t} = useTranslation();

    return (
        <>
            <Header
                title={t(TranslationKeys.chooseSubscriptionTitle)}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />
            <Body>
                <p className="whitespace-pre-line mb-6">{t(TranslationKeys.subscriptionDescription)}</p>
                <SubscriptionPackages/>
            </Body>
        </>
    );
};

export default RegisterDone;
