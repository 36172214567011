import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Body from "../../components/Body";
import ExamBlock from "../../components/ExamBlock";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";
import {useNavigate} from "react-router-dom";
import Toast from "../../components/Toast";
import {Exam, ExamStatus, Student, useExamsQuery, useStudentQuery} from "../../generated/graphql";
import {FaHome, FaPlus, FaSpinner} from "react-icons/fa";
import {IoIosCheckmarkCircle, IoMdPerson} from "react-icons/io";
import TabComponent from "../../components/Tabs";
import Spinner from "../../components/Spinner";

enum Tab {
    Home = 0,
    Finished = 1,
    Profile = 2,
}

const StudentHomePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [showIsCheckedToast, setShowIsBeingCheckedToast] = useState(false);
    const [showIsReadyForCheckingToast, setShowIsReadyForCheckingToast] = useState(false);

    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState(Tab.Home);

    const [profile, setProfile] = useState<Student>();
    const studentQuery = useStudentQuery();
    useEffect(() => setProfile(studentQuery?.data?.student), [studentQuery]);

    const [exams, setExams] = useState<Exam[]>([]);
    const examsQuery = useExamsQuery({fetchPolicy: "network-only"});
    useEffect(() => {
        setLoading(examsQuery.loading);
        setExams((examsQuery?.data?.exams || []) as Exam[])
    }, [examsQuery]);

    const openExams = exams.filter(e => e.status !== ExamStatus.IsAssessed);
    const finishedExams = exams.filter(e => e.status === ExamStatus.IsAssessed);

    let titleClassNames = "flex items-center text-2xl md:text-3xl py-4 font-semibold gap-4 mb-4";

    const buildTabs = () => {
        const tabs = [
            {id: Tab.Home.toString(), icon: <FaHome/>, translationKey: TranslationKeys.home},
            {id: Tab.Finished.toString(), icon: <IoIosCheckmarkCircle/>, translationKey: TranslationKeys.finished},
            {id: Tab.Profile.toString(), icon: <IoMdPerson/>, translationKey: TranslationKeys.profile},
        ];

        return <TabComponent
            activeTab={activeTab.toString()}
            setActiveTab={(t) => setActiveTab(parseInt(t))}
            t={t}
            tabs={tabs}
        />
    }

    const createExamButton = () => {
        return <div className="fixed bottom-24 md:bottom-8 left-0 right-0">
            <button
                disabled={loading}
                className="btn-main !w-max mx-auto bottom-4 mt-8 !px-6 flex items-center"
                onClick={() => navigate('/student/generate')}
            >
                {loading && <FaSpinner className="animate-spin"/>}
                <FaPlus className="text-sm"/>
                {t(TranslationKeys.createExam)}
            </button>
        </div>;
    }

    return <>
        <Header
            titleLabel={t(TranslationKeys.welcomeBack)}
            title={`${profile?.firstName} ${profile?.lastName}`}
            compact={false}
            rightComponent={<div>
                <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
            </div>}
        />
        <Body>
            {buildTabs()}
            {loading
                ? <Spinner/>
                : <div>

                    {activeTab === Tab.Home && (
                        <div>
                            <div className={titleClassNames}>
                                <img src="/img/icon_figure_rectangle.svg" alt="Openstaande toetsen" className="h-8"/>
                                {t(TranslationKeys.openExams)}
                            </div>
                            <div className="flex flex-col gap-4 pb-36 md:pb-24 lg:pb-0">
                                {openExams.length > 0 ? (
                                    openExams.map((t, idx) => {
                                        const onClick = () => {
                                            if (t.status === ExamStatus.BeingAssessed) {
                                                setShowIsBeingCheckedToast(true);
                                            } else if (t.status === ExamStatus.ReadyForAssessor) {
                                                setShowIsReadyForCheckingToast(true);
                                            } else {
                                                navigate(`/student/view/${t.id}`, {replace: true})
                                            }
                                        };

                                        return <div key={idx} className={`${idx > 0 && ''}`}>
                                            <ExamBlock exam={t} onClick={() => onClick()}/>
                                        </div>;
                                    })
                                ) : (
                                    <div
                                        className="dashed rounded flex flex-col items-center justify-center p-6 md:p-20 gap-4">
                                        <img src="/img/icon_exams_empty.svg" alt="Geen toetsen" className="h-24"/>
                                        <div className="w-1/2 text-center font-semibold text-gray-500">
                                            {t(TranslationKeys.noOpenExams)}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {createExamButton()}
                        </div>
                    )}

                    {activeTab === Tab.Finished && (
                        <div>
                            <div className={titleClassNames}>
                                <img src="/img/icon_figure_cylinder.svg" alt="Afgeronde toetsen" className="h-8"/>
                                {t(TranslationKeys.finishedExams)}
                            </div>
                            <div>
                                {finishedExams.length > 0 ? (
                                    finishedExams.map((t, idx) => {
                                        const onClick = () => navigate(`/student/view/${t.id}`, {replace: true});
                                        return <div key={idx} className={`${idx > 0 && 'mt-2'}`}>
                                            <ExamBlock exam={t} onClick={() => onClick()}/>
                                        </div>;
                                    })
                                ) : (
                                    <div
                                        className="dashed rounded flex flex-col items-center justify-center p-6 md:p-20 gap-4">
                                        <img src="/img/icon_exams_empty.svg" alt="Geen toetsen" className="h-24"/>
                                        <div className="w-1/2 text-center font-semibold text-gray-500">
                                            {t(TranslationKeys.noFinishedExams)}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {createExamButton()}
                        </div>
                    )}

                    {activeTab === Tab.Profile && (
                        <div>
                            <div className={titleClassNames}>
                                <img src="/img/icon_figure_stairs.svg" alt="Profiel" className="h-8"/>
                                {t(TranslationKeys.profile)}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="w-full">
                                    <div className="app-input-label">{t(TranslationKeys.email)}</div>
                                    <input
                                        value={profile?.email}
                                        disabled={true}
                                        className="app-input"
                                        type="email"
                                        placeholder={t(TranslationKeys.email)}
                                    />
                                </div>

                                <div className="w-full">
                                    <div className="app-input-label">{t(TranslationKeys.class)}</div>
                                    <input
                                        value={profile?.class.name}
                                        disabled={true}
                                        className="app-input"
                                        type="text"
                                        placeholder={t(TranslationKeys.class)}
                                    />
                                </div>

                                <button
                                    className="btn-danger-alt mt-4 flex items-center"
                                    onClick={() => navigate('/logout')}>
                                    {t(TranslationKeys.logout)}
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            }

            <Toast
                show={showIsCheckedToast}
                setShow={setShowIsBeingCheckedToast}
                delay={4000}
                message={t(TranslationKeys.examIsBeingChecked)}/>

            <Toast
                show={showIsReadyForCheckingToast}
                setShow={setShowIsReadyForCheckingToast}
                delay={4000}
                message={t(TranslationKeys.examIsReadyForChecking)}/>
        </Body>
    </>;
};

export default StudentHomePage;
