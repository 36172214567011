import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {useLocation, useNavigate} from "react-router-dom";
import {useLoginLazyQuery, UserType} from "../../generated/graphql";
import useAuthStore from "../../contexts/AuthStore";
import {FaSpinner} from "react-icons/fa";

interface Props {
    logout?: boolean;
}

const LoginPage = ({logout}: Props) => {
    const {t} = useTranslation();
    const authStore = useAuthStore();
    const navigate = useNavigate();
    const search = useLocation().search;
    const scope = new URLSearchParams(search).get('scope');

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");

    const allFieldsFilled = email && password;

    const [loginQuery, {loading, data, error}] = useLoginLazyQuery({fetchPolicy: "no-cache"});

    useEffect(() => {
        if (logout === true) authStore.logout();

        if (authStore.token?.scope) {
            navigate(`/${authStore.token.scope.toLowerCase()}`);
        } else {
            if (!['student', 'teacher', 'admin', 'parent'].includes(scope?.toLowerCase() || '')) {
                navigate('?scope=student')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getScopeUserType = (): UserType => {
        switch (scope?.toLowerCase()) {
            case 'student':
                return UserType.Student;
            case 'teacher':
                return UserType.Teacher;
            case 'admin':
                return UserType.Admin;
            case 'parent':
                return UserType.Parent;
            default:
                return UserType.Student;
        }
    };

    useEffect(() => {
        if (!data && !error) return;

        if (error) {
            if (error.message.includes("UNAUTHORIZED")) {
                return setLoginError(t(TranslationKeys.subscriptionExpiredError))
            }
            return setLoginError(t(TranslationKeys.loginFailedError));
        }

        if (data?.login) {
            authStore.setToken(data.login);
            switch (getScopeUserType()) {
                case UserType.Student:
                    navigate("/student");
                    break;
                case UserType.Teacher:
                    navigate("/teacher");
                    break;
                case UserType.Admin:
                    navigate("/admin");
                    break;
                case UserType.Parent:
                    navigate("/parent");
                    break;
                default:
                    navigate("/student");
                    break;
            }
            return;
        }

        setLoginError(t(TranslationKeys.errorLogin));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        setLoginError("");
    }, [email, password]);

    const login = async () => {
        await loginQuery({variables: {email, password, preferredScope: getScopeUserType()}});
    };

    return <>
        <Header
            titleLabel={t(TranslationKeys.welcomeBack)}
            title={t(TranslationKeys.login)}
            compact={false}
            rightComponent={<div>
                <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
            </div>}
        />
        <Body>
            <div className="pt-2 pb-6">
                {t(TranslationKeys.noAccountYet)} <a href="/register"
                                                     className="underline">{t(TranslationKeys.registerHere)}</a>
            </div>
            <div className="flex flex-col w-full gap-2">
                <div className="w-full">
                    <div className="app-input-label">{t(TranslationKeys.email)}</div>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="app-input"
                        type="email"
                        placeholder={t(TranslationKeys.email)}
                    />
                </div>

                <div className="w-full">
                    <div className="app-input-label">{t(TranslationKeys.password)}</div>
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="app-input"
                        type="password"
                        placeholder={t(TranslationKeys.password)}
                    />
                </div>

                <div className="w-full">
                    <div className="app-input-label">{t(TranslationKeys.loginAs)}</div>
                    <select
                        className="app-input"
                        onChange={(e) => {
                            navigate(`?scope=${e.target.value}`);
                        }}
                        value={scope?.toLowerCase()}>
                        <option value="student">{t(TranslationKeys.student)}</option>
                        <option value="teacher">{t(TranslationKeys.teacher)}</option>
                        <option value="parent">{t(TranslationKeys.parent)}</option>
                        <option value="admin">{t(TranslationKeys.admin)}</option>
                    </select>
                </div>

                <button
                    className="btn-main mt-2 mb-4"
                    onClick={login}
                    disabled={loading || !allFieldsFilled}
                >
                    {loading && <FaSpinner className="animate-spin"/>}
                    {t(TranslationKeys.loginButtonTitle)}
                </button>

                {loginError &&
                    <div className="mt-4 flex bg-red-300 text-red-900 p-4 rounded-lg">⚠️ {loginError}</div>}
            </div>
        </Body>
    </>
};

export default LoginPage;
