import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface RegistrationInfo {
    email: string;
    password: string;
    userId: string;
}
interface RegistrationStore {
    info: RegistrationInfo | null;
    setInfo: (to: RegistrationInfo) => void;
    clear: () => void;
}

const useRegistrationStore = create(persist<RegistrationStore>(
    (set, get) => ({
        info: null,
        setInfo: (info) => set({ info }),
        clear: () => set({ info: null }),
    }),
    {
        name: 'registration-storage',
    }
));

export default useRegistrationStore;
