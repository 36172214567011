import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";
import SelectBlock from "../../components/SelectBlock";
import Dropdown, {DropdownOption} from "../../components/Dropdown";
import {Chapter, Paragraph, useChaptersQuery, useGenerateExamMutation} from "../../generated/graphql";
import {useNavigate} from "react-router-dom";
import {FaInfoCircle} from "react-icons/fa";
import {IoClose, IoSearch} from "react-icons/io5";

const StudentGeneratePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [generateExamMutation] = useGenerateExamMutation();

    const [generating, setGenerating] = useState(false);
    const [selectedParagraphs, setSelectedParagraphs] = useState<Paragraph[]>([]);
    const [generateEnabled, setGenerateEnabled] = useState(false);
    useEffect(() => setGenerateEnabled(selectedParagraphs.length > 1), [selectedParagraphs])

    const [paragraphFilter, setParagraphFilter] = useState("");
    const [selectedChapter, setSelectedChapter] = useState<DropdownOption>();
    const [filteredParagraphs, setFilteredParagraphs] = useState<Paragraph[]>([]);

    const [chapters, setChapters] = useState<Chapter[]>([]);
    const chaptersQuery = useChaptersQuery();
    useEffect(() => {
        const chapters: Chapter[] = [];
        chaptersQuery?.data?.chapters.forEach(c => {
            if (c !== null) chapters.push(c as Chapter);
        });
        setChapters(chapters);
    }, [chaptersQuery])

    useEffect(() => setParagraphFilter(""), [selectedChapter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => filterParagraphs(), [selectedChapter, paragraphFilter]);

    const [options, setOptions] = useState<DropdownOption[]>([]);
    useEffect(() => {
        const newOptions: DropdownOption[] = [];
        chapters.forEach((c) => newOptions.push({title: c.title, value: c}));
        setOptions(newOptions);
    }, [chapters]);

    const filterParagraphs = () => {
        setFilteredParagraphs((selectedChapter?.value?.paragraphs || []).filter((p: Paragraph) =>
            p.title.toLowerCase().includes(paragraphFilter.toLowerCase()) ||
            p.abbreviation.toLowerCase().includes(paragraphFilter.toLowerCase()))
        );
    };

    const handleGenerate = async () => {
        setGenerating(true);

        const {errors} = await generateExamMutation({
            variables: {
                paragraphIds: selectedParagraphs.map(sp => sp.id)
            },
        });
        if (errors) {
            setGenerating(false);
            throw new Error("Uploaded failed (Question)");
        }
        setGenerating(false);

        navigate('/');
    };

    const buildParagraphSelectionList = (paragraphs: Paragraph[], selected: boolean) => {
        return paragraphs.map((p, idx) => {
            return <div key={idx} className={`${idx > 0 && 'mt-2'}`}>
                <SelectBlock
                    enabled={!generating}
                    selected={selectedParagraphs.findIndex(sp => sp.id === p.id) > -1}
                    onSelect={() => {
                        setSelectedParagraphs((sps) => {
                            let newSelectedParagraphs: Paragraph[] = [...sps];
                            if (sps.findIndex(sps => sps.id === p.id) > -1) {
                                newSelectedParagraphs = sps.filter(sps => sps.id !== p.id);
                            } else {
                                newSelectedParagraphs.push(p);
                            }
                            return newSelectedParagraphs;
                        });
                    }}
                    title={`${p.title} (${p.abbreviation})`}
                />
            </div>;
        })
    };

    return <>
        <Header
            title={t(TranslationKeys.generateExam)}
            backHref={!generating ? "/student/home" : ""}
            compact={true}
            rightComponent={<img src="/img/logo_min.png" alt="Logo min" className="h-10"/>}
        />
        <Body>
            <div className="mb-2">
                <div className="bg-[#e7fbff] text-[#14b3d4] flex justify-start items-center gap-3 p-3 rounded">
                    <FaInfoCircle className="text-xl"/>
                    {t(TranslationKeys.generateExamText)}
                </div>

                <div className="flex flex-col w-full gap-2 my-4 mb-6">
                    <div className="w-full">
                        <div className="font-semibold">{t(TranslationKeys.chapter)}</div>
                        <Dropdown
                            enabled={!generating}
                            options={options}
                            selectedOption={selectedChapter}
                            setSelectedOption={setSelectedChapter}
                            emptyLabel={t(TranslationKeys.selectChapter)}
                        />
                    </div>
                </div>

                <hr className="-mx-8"/>

                <div className="my-4 flex justify-end border rounded-l rounded-r items-center px-3">
                    <IoSearch className="text-gray-500"/>
                    <input
                        disabled={generating}
                        type="text"
                        className="w-full outline-none rounded-l px-4 py-2"
                        placeholder={t(TranslationKeys.paragraphFilterPlaceholder)}
                        value={paragraphFilter}
                        onChange={(e) => setParagraphFilter(e?.target?.value)}
                    />
                    {paragraphFilter.length > 0 &&
                        <IoClose className="cursor-pointer" onClick={() => !generating && setParagraphFilter("")}/>}
                </div>
            </div>

            <div className="mb-4 text-sm">
                {t(TranslationKeys.selectParagraphsInfo)}
            </div>

            <div className="mb-2">
                {buildParagraphSelectionList(filteredParagraphs, false)}
            </div>

            <button
                disabled={generating || !generateEnabled}
                className="btn-main !w-max mx-auto mt-8 !px-6 flex items-center"
                onClick={async () => await handleGenerate()}
            >
                {t(TranslationKeys.generate)} ({selectedParagraphs.length})
            </button>
        </Body>
    </>;
};

export default StudentGeneratePage;
