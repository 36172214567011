import {Dialog} from "@headlessui/react";
import TranslationKeys from "../localization/translationKeys";
import Dropdown, {DropdownOption} from "./Dropdown";
import {Class, useSetStudentClassMutation} from "../generated/graphql";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FaSpinner} from "react-icons/fa";


const ChangeClassDialog = ({ isOpen, close, classes, studentId, done }: {
    isOpen: boolean,
    close: () => void,
    classes: Class[],
    studentId: string,
    done: () => void,
}) => {
    const {t} = useTranslation();
    const [selectedClass, setSelectedClass] = useState<Class>();
    const [setStudentClassMutation, { data, loading, error }] = useSetStudentClassMutation();
    const [errorMessage, setError] = useState<string>("");

    const closeAndResetSelectedClass = useCallback(() => {
        setSelectedClass(undefined);
        close();
    }, [setSelectedClass, close])

    const changeClass = () => {
        setStudentClassMutation({
            variables: {
                studentID: studentId,
                classID: selectedClass?.id || ""
            }
        })
    }

    useEffect(() => {
        if (data) {
            closeAndResetSelectedClass();
            return done();
        }

        if (error) {
            console.error(error);
            if (error.message.includes("CANT_CHANGE_CLASS")) {
                return setError(t(TranslationKeys.changeClassError));
            }
            setError(t(TranslationKeys.genericError));
        }
    }, [data, done, error, setError, t, closeAndResetSelectedClass]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
            }}
            className="fixed z-50 inset-0 overflow-y-auto"
        >
            <div className="mx-2 flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50"/>

                <div className="relative bg-white rounded max-w-sm mx-auto p-6">
                    <Dialog.Title className="font-bold text-center">{t(TranslationKeys.changeClass)}</Dialog.Title>
                    <p className="py-2">{t(TranslationKeys.changeClassNote)}</p>
                    <Dropdown
                        selectedOption={selectedClass}
                        options={classes ? classes.map((c: Class) => {
                            return {title: c.name, value: c.name};
                        }) : []}
                        setSelectedOption={(option: DropdownOption) => {
                            if (option) {
                                const s = classes.find((e: Class) => e.name === option.value);
                                setSelectedClass(s);
                            }
                        }}
                        enabled
                    />

                    {errorMessage && <p className="bg-red-300 p-4 rounded-lg my-4 text-sm">{errorMessage}</p>}
                    <div className="flex justify-between gap-2">
                        <button disabled={loading} className="bg-none hover:bg-gray-50 text-gray-800 rounded p-2 mt-4 w-full outline-none"
                                onClick={closeAndResetSelectedClass}>
                            {t(TranslationKeys.cancel)}
                        </button>
                        <button disabled={!selectedClass || loading} className="mt-4 btn-main"
                                onClick={changeClass}>
                            {loading && <FaSpinner className="animate-spin"/>}
                            {t(TranslationKeys.changeClass)}
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}


export default ChangeClassDialog;
