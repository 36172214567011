import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import {FaCheck} from "react-icons/fa"

interface Props {
  show: boolean;
  setShow: Function;
  delay: number;
  message: string;
}

const Toast = ({ show, setShow, delay, message }: Props) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => setShow(false), delay);
    }
  }, [show, setShow, delay]);

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        id="toast-success"
        className="fixed left-0 right-0 bottom-10 mx-auto w-max shadow-xl flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
        role="alert"
      >
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-700 dark:text-green-200">
         <FaCheck />
        </div>
        <div className="ml-3 text-sm font-normal">{message}</div>
      </div>
    </Transition>
  );
};

export default Toast;
