import React from "react";
import "./App.css";
import "./localization/i18n";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import StudentHomePage from "./pages/student/StudentHomePage";
import StudentGeneratePage from "./pages/student/StudentGeneratePage";
import StudentViewExamPage from "./pages/student/StudentViewExamPage";
import NotFoundPage from "./pages/general/NotFoundPage";
import StudentUploadAnswersPage from "./pages/student/StudentUploadAnswersPage";
import TeacherHomePage from "./pages/teacher/TeacherHomePage";
import TeacherViewExamPage from "./pages/teacher/TeacherViewExamPage";
import AdminAddSumsPage from "./pages/admin/AdminAddSumsPage";
import {UserType} from "./generated/graphql";
import {useApolloClient} from "./providers/apolloClient";
import {ApolloProvider} from "@apollo/client";
import ProtectedPage from "./pages/ProtectedPage";
import LoginPage from "./pages/general/LoginPage";
import AdminLayout from "./components/AdminLayout";
import AdminHomePage from "./pages/admin/AdminHomePage";
import TeacherLayout from "./components/TeacherLayout";
import Register from "./pages/register/Register";
import VerifyEmail from "./pages/register/VerifyEmail";
import RegisterStudent from "./pages/register/RegisterStudent";
import useAuthStore from "./contexts/AuthStore";
import BuySubscription from "./pages/register/BuySubscription";
import ParentHome from "./pages/parent/ParentHome";
import PaymentStatus from "./pages/register/PaymentStatus";
import ManageSubscription from "./pages/parent/ManageSubscription";

const App = () => {
    const token = useAuthStore(state => state.token);
    const apolloClient = useApolloClient(token);

    return (
        <ApolloProvider client={apolloClient}>
            <BrowserRouter>
                <Routes>
                    {/*General*/}
                    <Route path="/" element={<Layout/>}>
                        <Route path="/" element={<Navigate to="/login"/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/register/verify-email" element={<VerifyEmail/>}/>
                        <Route path="/register/add-student" element={
                            <ProtectedPage
                                component={<RegisterStudent/>}
                                scope={UserType.Parent}/>
                        }/>
                        <Route path="/register/subscription" element={
                            <ProtectedPage
                                component={<BuySubscription/>}
                                scope={UserType.Parent}/>
                        }/>
                        <Route path="/payment-status" element={
                            <ProtectedPage
                                component={<PaymentStatus/>}
                                scope={UserType.Parent}/>
                        }/>
                        <Route path="/logout" element={<LoginPage logout={true}/>}/>
                    </Route>

                    {/*Parent*/}
                    <Route path="/parent" element={<Layout/>}>
                        <Route path="/parent" element={<Navigate to="/parent/home"/>}/>

                        <Route path="/parent/home" element={
                            <ProtectedPage
                                component={<ParentHome/>}
                                scope={UserType.Parent}/>
                        }/>
                        <Route path="/parent/subscription" element={
                            <ProtectedPage
                                component={<ManageSubscription/>}
                                scope={UserType.Parent}/>
                        }/>
                    </Route>

                    {/*Student*/}
                    <Route path="/student" element={<Layout/>}>
                        <Route path="/student" element={<Navigate to="/student/home"/>}/>

                        <Route path="/student/home" element={
                            <ProtectedPage
                                component={<StudentHomePage/>}
                                scope={UserType.Student}/>
                        }/>
                        <Route path="/student/generate" element={
                            <ProtectedPage
                                component={<StudentGeneratePage/>}
                                scope={UserType.Student}/>
                        }/>
                        <Route path="/student/view/:examIdentifier" element={
                            <ProtectedPage
                                component={<StudentViewExamPage/>}
                                scope={UserType.Student}/>
                        }/>
                        <Route path="/student/view/:examIdentifier/upload-answers" element={
                            <ProtectedPage
                                component={<StudentUploadAnswersPage/>}
                                scope={UserType.Student}/>
                        }/>
                    </Route>

                    {/*Teacher*/}
                    <Route path="/teacher" element={<Layout/>}>
                        <Route path="/teacher" element={<Navigate to="/teacher/home"/>}/>

                        <Route path="/teacher/home" element={
                            <ProtectedPage
                                component={<TeacherHomePage/>}
                                scope={UserType.Teacher}/>
                        }/>
                    </Route>
                    <Route path="/teacher" element={<TeacherLayout/>}>
                        <Route path="/teacher/view/:examIdentifier" element={
                            <ProtectedPage
                                component={<TeacherViewExamPage/>}
                                scope={UserType.Teacher}/>
                        }/>
                    </Route>

                    {/*Admin*/}
                    <Route path="/admin" element={<AdminLayout/>}>
                        <Route path="/admin" element={<Navigate to="/admin/home"/>}/>

                        <Route path="/admin/home" element={
                            <ProtectedPage
                                component={<AdminHomePage/>}
                                scope={UserType.Admin}/>
                        }/>
                        <Route path="/admin/add-sums" element={
                            <ProtectedPage
                                component={<AdminAddSumsPage/>}
                                scope={UserType.Admin}/>
                        }/>
                    </Route>

                    {/*404*/}
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default App;
