import React from "react";

interface Props {
    borderBottom?: boolean;
}

const PrintHeader = ({borderBottom}: Props) => {
    return <div className="print-header">
        <div className="flex flex-between mb-4 pt-10">
            <div className="flex items-center">
                <img src="/img/logo_full.png" alt="Toetssucces logo" className="w-2/3"/>
            </div>
            <div className="w-full text-right">
                <strong className="block">Oefentoets gegenereerd op</strong>
                <span className="block text-blue-500">www.toetssucces.nl</span>
            </div>
        </div>
        {borderBottom && <hr/>}
    </div>;
};

export default PrintHeader;