import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";
import {useNavigate, useParams} from "react-router-dom";
import ImageBlock from "../../components/ImageBlock";
import {useReactToPrint} from "react-to-print";
import PrintHeader from "../../components/PrintHeader";
import {FaCloudUploadAlt, FaPrint, FaSpinner} from "react-icons/fa";
import {AssessorAnswer, Exam, ExamAttachment, ExamStatus, Question, useExamQuery} from "../../generated/graphql";
import TabComponent from "../../components/Tabs";
import {HiOutlineChat} from "react-icons/hi";
import {ImAttachment} from "react-icons/im";

enum Tab {
    Exam = 0,
    Attachments = 1,
}

const StudentViewExamPage = () => {
    const {t} = useTranslation();
    const {examIdentifier} = useParams();
    const navigate = useNavigate();

    const printRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => printRef.current || null
    });

    const [activeTab, setActiveTab] = useState(Tab.Exam);

    const [loading, setLoading] = useState(false);
    const [exam, setExam] = useState<Exam>();
    const examQuery = useExamQuery({
        variables: {
            examId: examIdentifier || ""
        }
    });
    useEffect(() => {
        setLoading(examQuery.loading);

        if (examQuery.error) navigate('/404');
        else setExam(examQuery.data?.exam as Exam)

        if (!examQuery.error && examQuery.data) {
            setExam(examQuery.data?.exam as Exam)
        }
    }, [examQuery, navigate]);

    const buildTabs = () => {
        const tabs = [
            {id: Tab.Exam.toString(), icon: <HiOutlineChat/>, translationKey: TranslationKeys.exam},
            {id: Tab.Attachments.toString(), icon: <ImAttachment/>, translationKey: TranslationKeys.attachments},
        ];

        return <TabComponent
            activeTab={activeTab.toString()}
            setActiveTab={(t) => setActiveTab(parseInt(t))}
            t={t}
            tabs={tabs}
        />
    }

    const renderView = () => {
        if (exam) {
            switch (exam?.status) {
                case ExamStatus.NoAnswersUploaded:
                    return renderExamNoAnswersUploadedYet();
                case ExamStatus.IsAssessed:
                    return renderIsChecked();
                default:
                    navigate('/404');
            }
        }
    };

    const renderPrintableAttachmentBlocksList = (answers: AssessorAnswer[] | Question[] | undefined, titlePrefix: string) => {
        return <table className="bg-white w-full" ref={printRef}>
            <thead>
            <tr>
                <td><PrintHeader/></td>
            </tr>
            </thead>
            <tbody>
            {answers?.map((answer, idx) => {
                return <tr key={idx}>
                    <td>
                        <ImageBlock
                            title={`${titlePrefix} ${idx + 1}`}
                            id={answer.id}
                            imageURL={answer.imageURL}
                        />
                    </td>
                </tr>;
            })}
            </tbody>
        </table>;
    };

    const renderPrintableExamAttachmentBlocksList = (answers: ExamAttachment[] | undefined, titlePrefix: string) => {
        return <table className="bg-white w-full" ref={printRef}>
            <thead>
            <tr>
                <td><PrintHeader/></td>
            </tr>
            </thead>
            <tbody>
            {answers?.map((answer, idx) => {
                return <tr key={idx}>
                    <td>
                        <ImageBlock
                            title={`${titlePrefix} ${idx + 1}`}
                            id={answer.name}
                            imageURL={answer.attachmentURL}
                        />
                    </td>
                </tr>;
            })}
            </tbody>
        </table>;
    };

    const renderExamNoAnswersUploadedYet = () => {
        return <>
            <p className="mb-4">{t(TranslationKeys.generatedExamDescription)}</p>
            {renderPrintableAttachmentBlocksList(exam?.questions, t(TranslationKeys.question))}
        </>;
    };

    const renderIsChecked = () => {
        return <div>
            <p className="mb-3">{t(TranslationKeys.examIsCheckedByAssessor)}</p>
            {exam?.assessorNotes && (
                <p className="bg-gray-100 rounded px-4 py-3 mb-3 text-gray-700">
                    <b>Docent:</b> "{exam?.assessorNotes || "-"}"
                </p>
            )}
            <div>
                {buildTabs()}
                {activeTab === Tab.Exam && (
                    <div>
                        {renderPrintableAttachmentBlocksList(exam?.assessorAnswers, t(TranslationKeys.question))}
                    </div>
                )}
                {activeTab === Tab.Attachments && (
                    <div>
                        {renderPrintableExamAttachmentBlocksList(exam?.attachments, t(TranslationKeys.attachments))}
                    </div>
                )}
            </div>
        </div>;
    };

    return <>
        {exam?.status === ExamStatus.NoAnswersUploaded
            ? <>
                <Header
                    title={`${t(TranslationKeys.exam)} ${(new Date(exam?.createdAt).toLocaleDateString())}`}
                    backHref="/student/home"
                    compact={true}
                    headerAction={{
                        title: t(TranslationKeys.uploadAnswers),
                        icon: <FaCloudUploadAlt/>,
                        onClick: () => navigate(`/student/view/${examIdentifier}/upload-answers`, {replace: true}),
                        enabled: true
                    }}
                />
            </>
            : <>
                <Header
                    title={`${t(TranslationKeys.exam)} ${(new Date(exam?.createdAt).toLocaleDateString())}`}
                    backHref="/student/home"
                    compact={true}
                    headerAction={{
                        title: t(TranslationKeys.printExam),
                        icon: <FaPrint/>,
                        onClick: () => handlePrint(),
                        enabled: true
                    }}
                />
            </>}
        <Body>
            {exam?.status === ExamStatus.NoAnswersUploaded && (
                <div className="fixed bottom-24 md:bottom-8 left-0 right-0">
                    <button
                        disabled={loading}
                        className="btn-main !w-max mx-auto bottom-4 mt-8 !px-6 flex items-center"
                        onClick={() => navigate(`/student/view/${examIdentifier}/upload-answers`, {replace: true})}
                    >
                        {loading && <FaSpinner className="animate-spin"/>}
                        {t(TranslationKeys.uploadAnswers)}
                        <FaCloudUploadAlt/>
                    </button>
                </div>
            )}
            {renderView()}
        </Body>
    </>;
};

export default StudentViewExamPage;
