import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import TranslationKeys from "../../localization/translationKeys";
import {BsBook} from "react-icons/bs";
import Body from "../../components/Body";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Class, Parent, Student, useClassesQuery, useParentQuery} from "../../generated/graphql";
import ChangeClassDialog from "../../components/ChangeClassDialog";
import {FaHome, FaRegEnvelope} from "react-icons/fa";
import {IoMdPerson} from "react-icons/io";
import {IoCube} from "react-icons/io5";
import ManageSubscription from "./ManageSubscription";
import TabComponent from "../../components/Tabs";

enum Tab {
    Home = 0,
    Subscription = 1,
    Profile = 2,
}

const ParentHome = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [isChangingClassForStudent, setIsChangingClassForStudent] = useState<Student>();

    const [activeTab, setActiveTab] = useState(Tab.Home);

    const [classes, setClasses] = useState<Class[]>([]);

    const [students, setStudents] = useState<Student[]>([]);

    const classesQuery = useClassesQuery();
    const parentQuery = useParentQuery();

    const [profile, setProfile] = useState<Parent>();
    useEffect(() => setProfile(parentQuery?.data?.parent), [parentQuery]);

    useEffect(() => {
        setClasses(classesQuery?.data?.classes as Class[])
    }, [classesQuery]);

    useEffect(() => {
        setStudents(parentQuery?.data?.parent?.students || []);

        if (!parentQuery?.data?.parent) return;
        if (!parentQuery.data.parent.isVerified) return navigate("/register/verify-email");
        if (parentQuery.data.parent.students.length === 0) return navigate("/register/add-student");
        if (parentQuery.data.subscription === null) return navigate("/register/subscription");
    }, [parentQuery, navigate]);

    const buildTabs = () => {
        const tabs = [
            {id: Tab.Home.toString(), icon: <FaHome/>, translationKey: TranslationKeys.home},
            {id: Tab.Subscription.toString(), icon: <IoCube/>, translationKey: TranslationKeys.subscriptionTitle},
            {id: Tab.Profile.toString(), icon: <IoMdPerson/>, translationKey: TranslationKeys.profile},
        ];

        return <TabComponent
            activeTab={activeTab.toString()}
            setActiveTab={(t) => setActiveTab(parseInt(t))}
            t={t}
            tabs={tabs}
        />
    }

    return (
        <>
            <Header
                titleLabel={t(TranslationKeys.welcomeBack)}
                title={`${profile?.firstName} ${profile?.lastName}`}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />

            {!!isChangingClassForStudent && (
                <ChangeClassDialog
                    isOpen={!!isChangingClassForStudent}
                    close={() => {
                        setIsChangingClassForStudent(undefined);
                    }}
                    classes={classes}
                    done={() => {
                        parentQuery.refetch();
                    }}
                    studentId={isChangingClassForStudent?.id || ""}
                />
            )}

            <Body>
                {buildTabs()}
                <div>
                    {activeTab === Tab.Home && (
                        <div>
                            <div className="flex items-center text-xl font-semibold gap-2 mb-4">
                                <img src="/img/icon_figure_rectangle.svg" alt="Openstaande toetsen" className="h-6"/>
                                {t(TranslationKeys.activeAccounts)}
                            </div>
                            <div>
                                {students.map((student: Student) => (
                                    <div key={student.id} className="shadow rounded border p-4 flex flex-col gap-2">
                                        <div className="font-semibold">
                                            {student.firstName + " " + student.lastName}
                                        </div>
                                        <div className="flex items-center gap-2 text-sm text-gray-500 italic">
                                            <FaRegEnvelope/>
                                            {student.email}
                                        </div>
                                        <div className="flex items-center gap-2 text-sm text-gray-500">
                                            <BsBook/>
                                            {t(TranslationKeys.class)} {student.class.name}
                                            <span
                                                className="text-app-primary underline italic cursor-pointer"
                                                onClick={() => setIsChangingClassForStudent(student)}
                                            >
                                                ({t(TranslationKeys.changeClass)})
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {activeTab === Tab.Subscription && (
                        <ManageSubscription/>
                    )}

                    {activeTab === Tab.Profile && (
                        <>

                            <div>
                                <div className="flex items-center text-xl font-semibold gap-2 mb-4">
                                    <img src="/img/icon_figure_stairs.svg" alt="Profiel" className="h-6"/>
                                    {t(TranslationKeys.profile)}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="w-full">
                                        <div className="app-input-label">{t(TranslationKeys.email)}</div>
                                        <input
                                            value={profile?.email}
                                            disabled={true}
                                            className="app-input"
                                            type="email"
                                            placeholder={t(TranslationKeys.email)}
                                        />
                                    </div>

                                    <button
                                        className="btn-danger-alt mt-4 flex items-center"
                                        onClick={() => navigate('/logout')}>
                                        {t(TranslationKeys.logout)}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Body>
        </>
    );
};

export default ParentHome;
