import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import {Menu} from '@headlessui/react'
import {BsThreeDotsVertical} from "react-icons/bs";
import {FaArrowLeft} from "react-icons/fa";
import TranslationKeys from "../localization/translationKeys";
import {useTranslation} from "react-i18next";

export interface HeaderAction {
    title: string;
    icon: ReactElement;
    onClick: Function;
    enabled: boolean;
    loading?: boolean;
}

interface Props {
    titleLabel?: string;
    title: string,
    titleComponent?: ReactElement,
    rightComponent?: ReactElement,
    optionComponents?: ReactElement[],
    backHref?: string,
    backAction?: Function,
    compact?: boolean,
    headerAction?: HeaderAction
}

const Header = ({
                    titleLabel,
                    title,
                    titleComponent,
                    rightComponent,
                    optionComponents,
                    backHref,
                    backAction,
                    compact,
                    headerAction
                }: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const hasBackAction = !!backHref || !!backAction;
    const handleBack = () => {
        if (backHref) {
            navigate(backHref, {replace: true});
        } else if (backAction) {
            backAction();
        }
    };

    const buildHeaderAction = () => {
        if (!headerAction) return;

        return <div className={`md:h-6 ${!headerAction.enabled && 'opacity-70'}`}>
            <button
                disabled={!headerAction.enabled}
                className={`md:-mt-3.5 ${optionComponents && 'mr-2 md:mr-6'} px-4 py-2 md:bg-app-primary-200 text-black md:text-app-primary-900 md:rounded disabled:cursor-not-allowed`}
                onClick={() => headerAction.onClick()}>
                {headerAction.loading ? (
                    <svg
                        className="animate-spin -mt-1 h-5 w-5 text-white inline"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                ) : (
                    <div className="flex gap-2 items-center">
                        <div className="hidden md:block text-sm">
                            {headerAction.title}
                        </div>
                        <div className="block">
                            {headerAction.icon}
                        </div>
                    </div>
                )}
            </button>
        </div>;
    };

    const barContents = () => {
        return <div className="flex justify-between text-black">
            <div className="flex gap-4">
                {hasBackAction && (
                    <div className="flex items-center justify-start py-2">
                        <button onClick={() => handleBack()}>
                            <div
                                className="flex items-center text-sm bg-[#dcf4f9] py-2 px-3 gap-2 rounded font-bold hover:bg-[#d1e8ed] transition active:scale-95">
                                <FaArrowLeft/>
                                {t(TranslationKeys.back)}
                            </div>
                        </button>
                    </div>
                )}
                <div className={`flex ${hasBackAction ? "items-center justify-center" : "flex-col justify-start"}`}>
                    {titleLabel && <div className="mb-1 text-sm">{titleLabel}</div>}
                    <h1 className="font-bold whitespace-nowrap text-xl">{title}</h1>
                </div>
            </div>

            <div className="flex justify-end items-center">
                {buildHeaderAction()}
                <div>
                    {(optionComponents && optionComponents?.length > 0) && (
                        <Menu as="div" className="relative inline-block text-left text-black">
                            <Menu.Button className="outline-none">
                                <BsThreeDotsVertical
                                    className={`${compact ? 'lg:text-black' : 'lg:text-white'} text-white`}/>
                            </Menu.Button>
                            <Menu.Items as="ul"
                                        className="absolute -inset-x-60 w-60 bg-white rounded shadow-md mt-2 ml-4 outline-none">
                                {optionComponents.map((o, idx) => {
                                    return <Menu.Item
                                        className={`py-3 px-3 hover:bg-gray-100 cursor-pointer rounded`}
                                        key={idx} as="li">{({active}) => o}</Menu.Item>;
                                })}
                            </Menu.Items>
                        </Menu>
                    )}
                </div>
                {rightComponent}
            </div>
        </div>;
    };

    const desktopHeader = () => {
        if (compact) {
            return <div className="hidden lg:block rounded-t md:px-10 lg:px-12 py-3 mb-4 border-b">
                {barContents()}
            </div>;
        }

        return <div className="hidden lg:block md:px-10 lg:px-12 pt-6 md:pt-8">
            <div className="flex justify-between items-center">
                <div className="flex flex-col whitespace-nowrap">
                    {titleLabel && <div className="mb-1">{titleLabel}</div>}
                    <div className={`font-bold ${!!titleLabel ? "text-xl" : "text-2xl md:text-3xl"}`}>{title}</div>
                </div>

                <div className="flex items-center justify-end">
                    {buildHeaderAction()}
                    {rightComponent}
                </div>
            </div>
        </div>;
    };

    const mobileHeader = () => {
        return <div
            className={`fixed z-40 top-0 left-0 right-0 lg:hidden bg-white text-white px-4 md:px-8 py-3 ${hasBackAction ? "border-b" : "bg-gradient-to-b from-app-primary-50/40 to-white"}`}>
            {barContents()}
        </div>
    };

    return <div className="pb-12 lg:pb-0">
        {desktopHeader()}
        {mobileHeader()}
    </div>;
};

export default Header;
