import {useEffect, useState} from "react";
import {CropModel, DEFAULT_WIDTH} from "../../models/CropModel";
import PdfUpload from "./PdfUpload";
import utils from "./_utils";
import PageNavigation from "../../components/PageNavigation";
import ReactCrop, {Crop} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {AiFillDelete} from "react-icons/ai";

interface PdfCropperProps {
    history: Array<CropModel>;
    label: string;
    onChange: Function;
    deleteFromCropHistory: Function;
}

interface MaskProps {
    cropModels: Array<CropModel>;
    page: number;
}

interface DeleteButtonsProps {
    cropModels: Array<CropModel>;
    page: number;
    onClick: Function;
}

const Masks = (props: MaskProps) => {
    const {cropModels, page} = props;
    return (
        <div>
            {cropModels
                .filter((e: CropModel) => e.page === page)
                .map((e: CropModel, index: number) => {
                    return (
                        <div
                            key={index}
                            style={{
                                width: e.width,
                                height: e.height,
                                top: e.y,
                                position: "absolute",
                                pointerEvents: "none",
                            }}
                            className="opacity-70 bg-gray-800"
                        >
                            <p className={`text-white w-max px-2 left-0 top-0 absolute stroke-white font-bold`}>{`${
                                index + 1
                            } | Klas ${e.metadata?.classes?.name} - ${
                                e.metadata?.chapter?.title
                            } - ${e.metadata?.paragraph?.title}`}</p>
                        </div>
                    );
                })}
        </div>
    );
};

const DeleteButtons = (props: DeleteButtonsProps) => {
    const {cropModels, page, onClick} = props;
    return (
        <ul>
            {cropModels
                .filter((e: CropModel) => e.page === page)
                .map((e: CropModel, idx: number) => {
                    return (
                        <li
                            key={idx}
                            style={{
                                top: e.y + 220,
                                position: "absolute",
                            }}
                        >
                            <button
                                onClick={() => onClick(e)}
                                className="rounded-sm bg-red-600 p-2 mt-5 hover:shadow-md hover:bg-red-700"
                            >
                                <AiFillDelete className="w-5 h-5 text-white z-50"/>
                            </button>
                        </li>
                    );
                })}
        </ul>
    );
};

// Crop: internal model used by react-image-crop library
// CropModel: custom model enriched with some extra data that we need

const PdfCropper = (props: PdfCropperProps) => {
    const {history, label, onChange, deleteFromCropHistory} = props;

    // Uploaded PDF's and generated images
    const [pdf, setPdf] = useState<File | null>(null);
    const [images, setImages] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [crop, setCrop] = useState<Crop>({
        unit: "px", // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: DEFAULT_WIDTH,
        height: 200,
    });

    // When source pdf changes, generate new images for cropper to use
    useEffect(() => {
        const run = async () => {
            if (!pdf) {
                setImages([]);
                return;
            }
            const images = await utils.convertPdfToImages(pdf);
            setImages(images);
            setPage(0);
        };
        run();
    }, [pdf]);

    const onChangeCrop = (e: Crop) => {
        // Middleware function to make sure the width is always the full width of a page
        e.width = DEFAULT_WIDTH;
        e.x = 0;
        setCrop(e);
    };

    // Update the parent component with our enriched crop model
    useEffect(() => {
        const newCropModel: CropModel = {
            width: crop.width,
            height: crop.height,
            x: crop.x,
            y: crop.y,
            page,
            image: images[page],
            metadata: null, // Is added when the crop is confirmed
        };
        onChange(newCropModel);
    }, [crop, page, onChange, images]);

    return (
        <div className="grid grid-cols-12 gap-x-1">
            <div className="col-span-11">
                <PdfUpload enabled={true} setFile={setPdf} file={pdf} label={label}/>
            </div>
            <div className="col-span-1"/>
            <div style={{width: `${DEFAULT_WIDTH}px`}} className="col-span-11">
                {!!images.length && (
                    <>
                        <ReactCrop
                            crop={crop}
                            onChange={onChangeCrop}
                            className="mt-4 rounded-sm"
                        >
                            <img src={images[page]} alt="To be cropped"/>
                            <Masks cropModels={history} page={page}/>
                        </ReactCrop>
                        <PageNavigation
                            pages={images.length}
                            page={page}
                            setPage={setPage}
                        />
                    </>
                )}
            </div>
            <div className="col-span-1">
                <DeleteButtons
                    cropModels={history}
                    page={page}
                    onClick={deleteFromCropHistory}
                />
            </div>
        </div>
    );
};

export default PdfCropper;
