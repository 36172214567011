import React from "react";
import {Outlet} from "react-router-dom";

const Layout = () => {
    return <>
        <div
            className={`bg-white lg:rounded lg:max-w-3xl m-auto lg:mt-20 lg:shadow-xl h-screen lg:h-auto overflow-visible pb-12 md:pb-12 lg:pb-0 lg:mb-20`}>
            <Outlet/>
        </div>
    </>;
};

export default Layout;
