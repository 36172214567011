import {Package, PaymentType, useRequestMandateMutation} from "../generated/graphql";
import TranslationKeys from "../localization/translationKeys";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {FaSpinner} from "react-icons/fa";

const SubscriptionPackage = ({p, showBuyButton, isHighlighted}: {
    p: Package,
    showBuyButton: boolean,
    isHighlighted: boolean
}) => {
    const {t} = useTranslation();
    const [requestMandate, {data, loading, error}] = useRequestMandateMutation();

    const startPaymentFlow = () => {
        requestMandate({
            variables: {
                packageId: p.id
            }
        })
    }

    useEffect(() => {
        if (error) {
            return alert(t(TranslationKeys.genericError));
        }

        if (data?.requestMandate) {
            window.location.href = data.requestMandate;
        }
    }, [data, error, t])

    return (
        <div
            className={`shadow rounded px-4 py-4 flex flex-col gap-2 ${isHighlighted ? "bg-app-primary text-white" : "bg-white text-gray-800"}`}>
            <div
                className={`px-4 text-xs font-semibold py-1 mr-auto rounded-full uppercase bg-app-green text-white`}>
                {p.name}
            </div>

            <div className="flex items-center gap-1">
                <div className="text-2xl font-semibold">€{p.price}</div>
                <div className="transform translate-y-1">/{t(TranslationKeys.perMonth)}</div>
            </div>

            <p className={`text-xs`}>
                {p.paymentType === PaymentType.Monthly ? t(TranslationKeys.billedMonthly) : t(TranslationKeys.billedYearly)}
            </p>

            <ul className={`my-4 list-disc ml-6 flex flex-col gap-2 ${isHighlighted ? "text-white/75" : "text-black/75"} text-sm ml-3`}>
                {p.perks.map(perk => (
                    <li key={perk} className="">{perk}</li>
                ))}
            </ul>

            {showBuyButton && (
                <button
                    disabled={loading}
                    onClick={startPaymentFlow}
                    className={`rounded font-semibold hover:opacity-75 disabled:opacity-50 disabled:cursor-not-allowed text-sm px-2 py-2 flex justify-center items-center cursor-pointer ${isHighlighted ? "bg-white text-app-primary-700" : "bg-app-btn text-white"}`}
                >
                    {loading ? <FaSpinner className="animate-spin my-1"/> : t(TranslationKeys.choosePackage)}
                </button>
            )}
        </div>
    )
}

export default SubscriptionPackage;
