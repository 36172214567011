import {useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import {cropMetaData, CropModel, CropTypes, getDefaultCropModel,} from "../../models/CropModel";
import TranslationKeys from "../../localization/translationKeys";
import Body from "../../components/Body";
import SuccessToast from "../../components/SuccessToast";
import PdfCropper from "./PdfCropper";
import CropForm from "./CropForm";
import PopupDialog from "../../components/PopupDialog";
import {
    Chapter,
    Class,
    Difficulty,
    Paragraph,
    useCreateQuestionMutation,
    useFinishUploadMutation,
    useRequestUploadAnswerMutation,
    useRequestUploadQuestionMutation
} from "../../generated/graphql";
import {getImageData} from "../../helpers/getImageData";
import {imageDataLikeToDataURL} from "../../helpers/imageDataLikeToDataURL";
import {dataURLtoFile} from "../../helpers/dataURLToFile";
import {uploadFile} from "../../providers/uploadFile";

const defaultState: cropMetaData = {
    type: null,
    id: null,
    pairId: null,
};

const reducer = (state: cropMetaData, {key, value}: any) => {
    return {...state, [key]: value};
};

const AdminAddSumsPage = () => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    const [requestUploadQuestionMutation] = useRequestUploadQuestionMutation();
    const [requestUploadAnswerMutation] = useRequestUploadAnswerMutation();
    const [finishUploadMutation] = useFinishUploadMutation();
    const [createQuestionMutation] = useCreateQuestionMutation();

    const [state, dispatch] = useReducer(reducer, defaultState);
    const [showIsCheckedToast, setShowIsCheckedToast] = useState(false);
    const [cropHistory, setCropHistory] = useState<CropModel[]>([]);
    const [itemToRemove, setItemToRemove] = useState<CropModel | null>(null);

    const [selectedChapter, setSelectedChapter] = useState<Chapter>();
    const [selectedParagraph, setSelectedParagraph] = useState<Paragraph>();
    const [selectedDifficulty, setSelectedDifficulty] = useState<Difficulty>();
    const [selectedClasses, setSelectedClasses] = useState<Class>();
    const [selectedDuration, setSelectedDuration] = useState<number>();

    const [questionCrop, setQuestionCrop] = useState<CropModel>(getDefaultCropModel());
    const [questionCropObjectId, setQuestionCropObjectId] = useState("");

    const [answerCrop, setAnswerCrop] = useState<CropModel>(getDefaultCropModel());
    const [answerCropObjectId, setAnswerCropObjectId] = useState("");

    const uploadQuestion = async () => {
        const {data, errors} = await requestUploadQuestionMutation();
        if (errors) throw new Error("Uploaded failed (Question)");

        const {uploadURL, objectId} = data?.requestUpload || {};
        if (!uploadURL || !objectId) throw new Error('No uploadUrl or objectId (Question)');
        setQuestionCropObjectId(objectId);

        // Process image
        const imageData = await getImageData(questionCrop?.image, questionCrop.y, questionCrop.height, 17, 2);
        const croppedBase64 = imageDataLikeToDataURL(imageData);

        const file = dataURLtoFile(croppedBase64, Math.random().toString().substring(2, 8));
        await uploadFile(file, uploadURL);
        await finishUpload(objectId, 'Question');
    };

    const uploadAnswer = async () => {
        const {data, errors} = await requestUploadAnswerMutation();
        if (errors) throw new Error("Uploaded failed (Answer)");

        const {uploadURL, objectId} = data?.requestUpload || {};
        if (!uploadURL || !objectId) throw new Error('No uploadUrl or objectId (Answer)');
        setAnswerCropObjectId(objectId);

        // Process image
        const imageData = await getImageData(answerCrop?.image, answerCrop.y, answerCrop.height, 20, 4);
        const croppedBase64 = imageDataLikeToDataURL(imageData);

        const file = dataURLtoFile(croppedBase64, Math.random().toString().substring(2, 8));
        await uploadFile(file, uploadURL);
        await finishUpload(objectId, 'Answer');
    };

    const finishUpload = async (objectId: string, tag: string) => {
        const {errors} = await finishUploadMutation({variables: {objectId}});
        if (errors) throw new Error(`Finish upload failed (${tag})`);
    };

    const createQuestion = async () => {
        const {errors} = await createQuestionMutation({
            variables: {
                objectId: questionCropObjectId,
                answerObjectId: answerCropObjectId,
                paragraphIDs: selectedParagraph!.id,
                difficulty: (Difficulty as any)[(selectedDifficulty as any).value],
                durationInMinutes: selectedDuration || 0,
            }
        })
        if (errors) throw new Error(`Create question failed`);
    };

    const confirmSelection = async () => {
        // Add two new crops to the crop history, and enrich them with metadata
        const questionId = uuidv4();
        const answerId = uuidv4();

        // Upload question and answer to server and create question
        try {
            setLoading(true);

            await uploadQuestion();
            await uploadAnswer();
            await createQuestion();

            // Set history
            const selectionMetadata = {
                chapter: selectedChapter,
                paragraph: selectedParagraph,
                difficulty: selectedDifficulty,
                classes: selectedClasses,
                duration: selectedDuration,
            };

            setCropHistory((prevState) => [
                ...prevState,
                {
                    ...questionCrop,
                    metadata: {
                        ...state,
                        type: CropTypes.QUESTION,
                        id: questionId,
                        pairId: answerId,
                        ...selectionMetadata
                    },
                },
                {
                    ...answerCrop,
                    metadata: {
                        ...state,
                        type: CropTypes.ANSWER,
                        id: answerId,
                        pairId: questionId,
                        ...selectionMetadata
                    },
                },
            ]);

            setLoading(false);
            setShowIsCheckedToast(true);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const questionCropHistory = cropHistory.reduce(
        (acc: any, curr: CropModel) => {
            if (curr.metadata?.type === CropTypes.QUESTION) acc.push(curr);
            return acc;
        },
        []
    );

    const answerCropHistory = cropHistory.reduce((acc: any, curr: CropModel) => {
        if (curr.metadata?.type === CropTypes.ANSWER) acc.push(curr);
        return acc;
    }, []);

    const deleteFromCropHistory = () => {
        if (!itemToRemove) return;
        setCropHistory((old) =>
            old.filter(
                (item) =>
                    item.metadata?.id !== itemToRemove.metadata?.id &&
                    item.metadata?.id !== itemToRemove.metadata?.pairId
            )
        );
        setItemToRemove(null);
    };

    return (
        <Body>
            <PopupDialog
                show={!!itemToRemove}
                setShow={() => {
                }}
                title={t(TranslationKeys.areYouSure)}
                message={t(
                    TranslationKeys.removeQuestionAnswerCropConfirmationDescription
                )}
                yesButtonTitle={t(TranslationKeys.remove)}
                yesButtonAction={deleteFromCropHistory}
                noButtonTitle={t(TranslationKeys.back)}
                noButtonAction={() => {
                    setItemToRemove(null);
                }}
            />
            <div className={`${loading && 'pointer-events-none'}`}>
                <div className="grid grid-cols-5 gap-4">
                    {/* Context info */}
                    <div className="col-span-5">
                        <h2 className="text-md font-bold">Splitsen</h2>
                        <p className="text-md ">{t(TranslationKeys.cropperInfo)}</p>
                    </div>

                    {/* Pdf with questions */}
                    <div className="col-span-2">
                        <PdfCropper
                            label={t(TranslationKeys.uploadQuestionPdfInfo)}
                            history={questionCropHistory}
                            onChange={setQuestionCrop}
                            deleteFromCropHistory={setItemToRemove}
                        />
                    </div>

                    {/* Pdf with answers */}
                    <div className="col-span-2">
                        <PdfCropper
                            label={t(TranslationKeys.uploadAnswerPdfInfo)}
                            history={answerCropHistory}
                            onChange={setAnswerCrop}
                            deleteFromCropHistory={setItemToRemove}
                        />
                    </div>

                    {/* Dropdowns */}
                    <div className="mt-12">
                        <CropForm
                            loading={loading}
                            dispatch={dispatch}
                            confirm={confirmSelection}
                            selectedChapter={selectedChapter}
                            setSelectedChapter={setSelectedChapter}
                            selectedParagraph={selectedParagraph}
                            setSelectedParagraph={setSelectedParagraph}
                            selectedDifficulty={selectedDifficulty}
                            setSelectedDifficulty={setSelectedDifficulty}
                            selectedClasses={selectedClasses}
                            setSelectedClasses={setSelectedClasses}
                            selectedDuration={selectedDuration}
                            setSelectedDuration={setSelectedDuration}
                            answerCrop={answerCrop}
                            questionCrop={questionCrop}
                        />
                    </div>

                    {/* Toast */}
                    <SuccessToast
                        show={showIsCheckedToast}
                        setShow={setShowIsCheckedToast}
                        delay={3000}
                        message="Som toegevoegd"
                    />
                </div>
            </div>
        </Body>
    );
};

export default AdminAddSumsPage;
