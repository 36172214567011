import {Chapter, Class, Difficulty, Paragraph} from "../generated/graphql";

export const DEFAULT_WIDTH = 565;

export enum CropTypes {
    QUESTION,
    ANSWER,
}

export interface cropMetaData {
    chapter?: Chapter;
    paragraph?: Paragraph;
    difficulty?: Difficulty;
    classes?: Class;
    type: CropTypes | null;
    id: string | null;
    pairId: string | null;
}

export interface CropModel {
    width: number;
    height: number;
    x: number;
    y: number;
    page: number;
    image: string;
    metadata: cropMetaData | null;
}

export const getDefaultCropMetaData = (type: CropTypes): cropMetaData => {
    return {
        type,
        id: null,
        pairId: null,
    };
};

const defaultCropModel: CropModel = {
    width: DEFAULT_WIDTH,
    height: 200,
    page: 0,
    image: "",
    x: 0,
    y: 0,
    metadata: null,
};

export const getDefaultCropModel = (): CropModel => ({...defaultCropModel}); // Return a copy
