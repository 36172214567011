enum TranslationKeys {
    welcomeBack = "welcome-back",
    myExams = "my-exams",
    profile = "profile",
    paragraphs = "paragraphs",
    class = "class",
    logout = "logout",
    generateExam = "generate-exam",
    selectParagraphs = "select-paragraphs",
    exam = "exam",
    answers = "answers",
    answer = "answer",
    attachments = "attachments",
    attachment = "attachment",
    clear = "clear",
    file = "file",
    submit = "submit",

    finished = "finished",

    uploadAnswers = "upload-answers",
    uploadImagesInfo = "upload-images-info",
    handInAnswers = "hand-in-answers",
    handInAnswersInfo = "hand-in-answers-info",
    handInAnswersConfirmationDescription = "hand-in-answers-confirmation-description",
    removeQuestionAnswerCropConfirmationDescription = "remove-question-answer-crop-confirmation-description",
    areYouSure = "are-you-sure",
    handIn = "hand-in",
    remove = "remove",
    back = "back",
    generate = "generate",
    printExam = "print-exam",
    deleteExam = "delete-exam",
    closeWindow = "close-window",
    generatedExamDescription = "generated-exam-description",
    question = "question",
    examIsBeingChecked = "exam-is-being-checked",
    examIsCheckedByAssessor = "exam-is-checked-by-assessor",
    examIsReadyForChecking = "exam-is-ready-for-checking",

    notFoundTitle = "not-found-title",
    notFoundDescription = "not-found-description",
    notFoundReturnLinkTitle = "not-found-return-link-title",
    examStatusPrefix = "exam-status-",

    paragraphFilterPlaceholder = "paragraph-filter-placeholder",
    noParagraphsSelected = "no-paragraphs-selected",
    noParagraphsFoundSearch = "no-paragraphs-found-search",
    selectedParagraphsDescription = "selected-paragraphs-description",

    exams = "exams",
    classes = "classes",

    home = "home",
    addSums = "add-sums",
    uploadQuestionPdfInfo = "upload-question-pdf-info",
    uploadAnswerPdfInfo = "upload-answer-pdf-info",
    new = "new",
    checked = "checked",
    examOf = "exam-of",
    cropperInfo = "cropper-info",

    login = "login",
    email = "email",
    password = "password",
    loginButtonTitle = "login-button-title",

    errorLogin = "error-login",

    loginAs = "login-as",
    student = "student",
    teacher = "teacher",
    admin = "admin",
    or = "or",

    selectValue = "select-value",
    selectChapter = "select-chapter",
    choosePackage = "choose-package",

    noExamsGenerated = "no-exams-generated",

    claimExamDescription = "claim-exam-description",
    startAssessing = "start-assessing",

    addImageAttachments = "add-image-attachments",
    completeAssessingConfirmationDescription = "complete-assessing-confirmation-description",
    noAccountYet = "no-account-yet",
    alreadyHaveAccount = "already-have-account",
    loginHere = "login-here",
    registerHere = "register-here",
    register = "register",
    registerFailed = "register-failed",
    loading = "loading",
    registerTextParent = "register-text-parent",
    registerTextStudent = "register-text-student",
    continue = "continue",
    emailVerificationTitle = "email-verification-title",
    emailVerificationDescription = "email-verification-description",
    emailVerificationCode = "email-verification-code",
    resendEmail = "resend-email",
    verifyEmailFailed = "verify-email-failed",
    studentInfoTitle = "student-info-title",
    studentInfoDescription = "student-info-description",
    firstName = "first-name",
    lastName = "last-name",
    level = "level",
    subscriptionTitle = "subscription-title",
    chooseSubscriptionTitle = "choose-subscription-title",
    subscriptionDescription = "subscription-description",
    perMonth = "per-month",
    billedMonthly = "billed-monthly",
    billedYearly = "billed-yearly",
    subscriptionOneTitle = "subscription-one-title",
    subscriptionOneDescription = "subscription-one-description",
    subscriptionOnePrice = "subscription-one-price",
    toPaymentTitle = "to-payment-title",
    registerSuccessTitle = "register-success-title",
    registerSuccessDescription = "register-success-description",
    overview = "overview",
    activeAccounts = "active-accounts",
    changeClass = "change-class",
    validTill = "valid-till",
    manageSubscription = "manage-subscription",
    emailStudent = "email-student",
    passwordStudent = "password-student",
    registerStudentFailed = "register-student-failed",
    registerDoneText = "register-done-text",
    registerDoneTitle = "register-done-title",
    fetchError = "fetch-error",
    parent = "parent",
    genericError = "generic-error",
    cancel = "cancel",
    subscriptionActiveTitle = "subscription-active-title",
    subscriptionPendingTitle = "subscription-pending-title",
    subscriptionNoneTitle = "subscription-none-title",
    subscriptionActiveText = "subscription-active-text",
    subscriptionPendingText = "subscription-pending-text",
    subscriptionNoneText = "subscription-none-text",
    cancelSubscription = "cancel-subscription",
    confirmation = "confirmation",
    cancelSubscriptionText = "cancel-subscription-text",
    toHome = "to-home",
    subscriptionExpiredError = "subscription-expired-error",
    noSubscription = "no-subscription",
    subscriptionCancelled = "subscription-cancelled",
    yourSubscription = "your-subscription",
    changeClassNote = "change-class-note",
    changeClassError = "change-class-error",
    loginFailedError = "login-failed-error",
    emailExistsError = "email-exists-error",

    openExams = "open-exams",
    noOpenExams = "no-open-exams",

    finishedExams = "finished-exams",
    noFinishedExams = "no-finished-exams",

    createExam = "create-exam",

    currentSubscription = "current-subscription",

    generateExamText = "generate-exam-text",

    chapter = "chapter",

    selectParagraphsInfo = "select-paragraphs-info",
}

export default TranslationKeys;
