import React from 'react';

interface Tab {
    id: string;
    icon: JSX.Element;
    translationKey: string;
}

interface TabComponentProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    t: (key: string) => string;
    tabs: Tab[];
}

const TabComponent: React.FC<TabComponentProps> = ({ activeTab, setActiveTab, t, tabs }) => {
    return (
        <>
            <div className="hidden md:block">
                <div className="flex justify-start border-b -mx-8 px-8 mb-4 text-sm gap-8">
                    {tabs.map(tab => tabButton(tab, activeTab, setActiveTab, t))}
                </div>
            </div>

            <div className="block md:hidden bg-white fixed bottom-0 left-0 right-0 border-t z-20">
                <div className="flex text-sm gap-4 justify-evenly">
                    {tabs.map(tab => tabButtonMobile(tab, activeTab, setActiveTab, t))}
                </div>
            </div>
        </>
    );
};

const tabButton = (tab: Tab, activeTab: string, setActiveTab: (tab: string) => void, t: (key: string) => string) => (
    <button
        key={tab.id}
        className={`flex text-lg items-center font-medium gap-3 py-2 text-gray-400 ${activeTab === tab.id ? 'tab-active' : ''}`}
        onClick={() => setActiveTab(tab.id)}
    >
        {tab.icon}
        {t(tab.translationKey)}
    </button>
);

const tabButtonMobile = (tab: Tab, activeTab: string, setActiveTab: (tab: string) => void, t: (key: string) => string) => (
    <button
        key={tab.id}
        className={`flex flex-col items-center font-semibold p-4 text-gray-400 ${activeTab === tab.id ? 'tab-active-m' : ''}`}
        onClick={() => setActiveTab(tab.id)}
    >
        {tab.icon}
        {t(tab.translationKey)}
    </button>
);

export default TabComponent;
