import React from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../localization/translationKeys";

const AdminLayout = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return <>
        <div className="">
            <div className="flex">
                <aside className="w-64">
                    <div className="h-screen min-h-full overflow-y-auto py-4 px-3 bg-gray-50 dark:bg-gray-800">
                        <ul className="space-y-2">
                            <li>
                                <button
                                    className="w-full text-left py-2 px-4 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={() => navigate('/admin/home')}>
                                    {t(TranslationKeys.home)}
                                </button>
                            </li>
                            <li>
                                <button
                                    className="w-full text-left py-2 px-4 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={() => navigate('/admin/add-sums')}>
                                    {t(TranslationKeys.addSums)}
                                </button>
                            </li>
                            <li>
                                <button
                                    className="w-full text-left py-2 px-4 rounded-lg dark:text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                                    onClick={() => navigate('/logout')}>
                                    {t(TranslationKeys.logout)}
                                </button>
                            </li>
                        </ul>
                    </div>
                </aside>
                <div className="bg-gray-200 w-full p-6">
                    <Outlet/>
                </div>
            </div>
        </div>
    </>;
};

export default AdminLayout;