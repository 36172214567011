import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header";
import TranslationKeys from "../../localization/translationKeys";
import Body from "../../components/Body";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {Tab} from "@headlessui/react";
import ImageBlock from "../../components/ImageBlock";
import PrintHeader from "../../components/PrintHeader";
import ImageUpload from "../../components/ImageUpload";
import {IoMdCheckmark} from "react-icons/io";
import {
    Exam,
    ExamStatus,
    useAddExamAttachmentMutation,
    useAddExamNotesMutation,
    useAssessExamMutation,
    useExamQuery,
    useFinishAssessingExamMutation,
    useFinishUploadMutation,
    useRequestUploadAnswerMutation, useRequestUploadAttachmentMutation
} from "../../generated/graphql";
import {uploadFile} from "../../providers/uploadFile";
import PopupDialog from "../../components/PopupDialog";

interface AssessorAnswer {
    attachmentId: string;
    data: string;
}

const TeacherViewExamPage = () => {
    const {t} = useTranslation();
    const {examIdentifier} = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const printRef = useRef<any>();
    // const handlePrint = useReactToPrint({
    //     content: () => printRef.current || null
    // });

    const [exam, setExam] = useState<Exam>();
    const examQuery = useExamQuery({
        variables: {
            examId: examIdentifier || ""
        }
    });
    useEffect(() => {
        setLoading(examQuery.loading);

        if (examQuery.error) navigate('/404');
        else setExam(examQuery.data?.exam as Exam)

        if (!examQuery.error && examQuery.data) {
            setExam(examQuery.data?.exam as Exam)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examQuery]);

    const [assessorAnswers, setAssessorAnswers] = useState<AssessorAnswer[]>([]);
    const [assessorNotes, setAssessorNotes] = useState(exam?.assessorNotes || "");

    const [requestUploadAnswerMutation] = useRequestUploadAnswerMutation();
    const [requestUploadAttachmentMutation] = useRequestUploadAttachmentMutation();
    const [finishUploadMutation] = useFinishUploadMutation();
    const [assessExamMutation] = useAssessExamMutation();
    const [addExamNotesMutation] = useAddExamNotesMutation();
    const [addExamAttachmentMutation] = useAddExamAttachmentMutation();
    const [finishAssessingExamMutation] = useFinishAssessingExamMutation();

    const handleSubmit = () => {
        setShowConfirmationDialog(true);
    };

    const uploadAnswers = async () => {
        setShowConfirmationDialog(false);
        setLoading(true);

        // 1. Add assessor answers
        assessorAnswers.map(async (aa) => {
            try {
                const response = await fetch(aa.data);
                const blob = await response.blob();
                const file = new File([blob], `${aa.attachmentId}.png`, {type: blob.type});
                await uploadAnswer(file);
            } catch (e) {
                console.log(e);
            }
        });

        // 2. Add notes
        await uploadExamNotes();

        // 3. Add attachments
        for await (const f of attachments) {
            try {
                await uploadAttachment(f);
            } catch (e) {
                console.log(e);
            }
        }

        // 4. Finish assessing
        await finishAssessment();

        // 5. Navigate back to home
        navigate('/')
    };

    const uploadAnswer = async (file: File) => {
        const {data, errors} = await requestUploadAnswerMutation();
        if (errors) throw new Error("Uploaded failed (Answer)");

        const {uploadURL, objectId} = data?.requestUpload || {};
        if (!uploadURL || !objectId) throw new Error('No uploadUrl or objectId (Answer)');

        await uploadFile(file, uploadURL);
        await finishUploadAndAssessExam(file, objectId, 'Teacher Answer');
    };

    const finishUploadAndAssessExam = async (file: File, objectId: string, tag: string) => {
        const {errors} = await finishUploadMutation({variables: {objectId}});
        if (errors) throw new Error(`Finish upload failed (${tag})`);
        await assessExam(objectId);
    };

    const assessExam = async (objectId: string) => {
        const {errors} = await assessExamMutation({
            variables: {
                examId: examIdentifier || "",
                objectId: objectId
            }
        });
        if (errors) throw new Error(`Assess exam failed.`);
    };

    const uploadAttachment = async (file: File) => {
        const {data, errors} = await requestUploadAttachmentMutation();
        if (errors) throw new Error("Uploaded failed (Attachment)");

        const {uploadURL, objectId} = data?.requestUpload || {};
        if (!uploadURL || !objectId) throw new Error('No uploadUrl or objectId (Attachment)');

        await uploadFile(file, uploadURL);
        await finishUploadAndAddAttachment(file, objectId, 'Teacher Attachment');
    };

    const finishUploadAndAddAttachment = async (file: File, objectId: string, tag: string) => {
        const {errors} = await finishUploadMutation({variables: {objectId}});
        if (errors) throw new Error(`Finish upload failed (${tag})`);
        await addExamAttachment(objectId);
    };

    const addExamAttachment = async (objectId: string) => {
        const {errors} = await addExamAttachmentMutation({
            variables: {
                examId: examIdentifier || "",
                objectId: objectId,
                name: "-"
            }
        });
        if (errors) throw new Error(`Adding exam attachment failed.`);
    };

    const uploadExamNotes = async () => {
        const {errors} = await addExamNotesMutation({
            variables: {
                examId: exam?.id || "",
                notes: assessorNotes
            }
        });
        if (errors) throw new Error(`Add note failed.`);
    };

    const finishAssessment = async () => {
        const {errors} = await finishAssessingExamMutation({variables: {examId: exam?.id || ""}});
        if (errors) throw new Error(`Finish assessing exam failed.`);
    };

    const renderPrintableAttachmentBlocksList = () => {
        return <table className="bg-white w-full" ref={printRef}>
            <thead>
            <tr>
                <td><PrintHeader/></td>
            </tr>
            </thead>
            <tbody>
            {exam?.studentAnswers?.map((sa, idx) => {
                return <tr key={idx}>
                    <td>
                        <ImageBlock
                            title={`${t(TranslationKeys.attachment)} ${idx + 1}`}
                            id={sa.id}
                            imageURL={sa.imageURL}
                            drawable={exam?.status !== ExamStatus.IsAssessed}
                            data={assessorAnswers.find(a => a.attachmentId === sa.id)?.data || null}
                            setData={(data: string) => {
                                setAssessorAnswers((answers) => {
                                    const newAnswers = [...answers.filter(a => a.attachmentId !== sa.id)];
                                    newAnswers.push({attachmentId: sa.id, data});
                                    return newAnswers;
                                });
                            }}
                            noPrint={true}
                        />
                    </td>
                </tr>;
            })}
            </tbody>
        </table>;
    };

    const renderPrintableQuestionAndAnswersBlocksList = () => {
        return <table className="bg-white w-full" ref={printRef}>
            <thead>
            <tr>
                <td><PrintHeader/></td>
            </tr>
            </thead>
            <tbody>
            {exam?.questions?.map((question, idx) => {
                return <tr key={idx}>
                    <td className={`${idx !== 0 && 'border-y'}`}>
                        <ImageBlock
                            title={`${t(TranslationKeys.question)} ${idx + 1}`}
                            id={`q-${question.id}`}
                            imageURL={question.imageURL}
                            withBorder={false}
                            noPrint={true}
                        />
                        <ImageBlock
                            title={`${t(TranslationKeys.answer)} ${idx + 1}`}
                            id={`a-${question.id}`}
                            imageURL={question.answerImageURL}
                            withBorder={false}
                            noPrint={true}
                        />
                    </td>
                </tr>;
            })}
            </tbody>
        </table>;
    };

    const renderAttachmentsAndNotes = () => {
        return <div>
            <div>
                <span className="block font-bold">Notities voor de student</span>
                <textarea
                    className="mt-1 px-3 py-2 bg-gray-50 border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
                    value={assessorNotes}
                    onChange={(e) => setAssessorNotes(e.target.value)}
                />
            </div>
            <div>
                <span className="block font-bold mt-2">Bestanden uploaden</span>
                <ImageUpload
                    enabled={!loading}
                    files={attachments}
                    setFiles={setAttachments}
                    title={t(TranslationKeys.addImageAttachments)}
                    deleteEnabled={true}
                />
            </div>
        </div>;
    };

    return <>
        {exam?.status !== ExamStatus.IsAssessed
            ? <>
                <Header
                    title={t(TranslationKeys.exams)}
                    backHref="/teacher/home"
                    compact={true}
                    headerAction={{
                        title: t(TranslationKeys.submit),
                        icon: <IoMdCheckmark/>,
                        onClick: () => handleSubmit(),
                        enabled: true,
                        loading: loading
                    }}
                />
            </> : <>
                <Header
                    title={t(TranslationKeys.exams)}
                    backHref="/teacher/home"
                    compact={true}
                />
            </>
        }
        <Body>
            <PopupDialog
                show={showConfirmationDialog}
                setShow={setShowConfirmationDialog}
                title={t(TranslationKeys.areYouSure)}
                message={t(TranslationKeys.completeAssessingConfirmationDescription)}
                yesButtonTitle={t(TranslationKeys.submit)}
                yesButtonAction={async () => await uploadAnswers()}
                noButtonTitle={t(TranslationKeys.back)}
                noButtonAction={() => setShowConfirmationDialog(false)}
            />
            <div>
                <div>
                    {loading
                        ? <svg
                            className="animate-spin h-5 w-5 text-black inline"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        : <>
                            <Tab.Group>
                                <Tab.List className="border rounded w-full flex">
                                    <Tab
                                        className={({selected}) => `p-1 rounded-l ${selected ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-500'} w-full outline-none`}>
                                        {t(TranslationKeys.exam)}
                                    </Tab>
                                    <Tab
                                        className={({selected}) => `p-1 rounded-r ${selected ? 'bg-gray-600 text-white' : 'bg-gray-200 text-gray-500'} w-full outline-none`}>
                                        {t(TranslationKeys.attachments)}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className="mt-2">
                                    <Tab.Panel>
                                        <div className="grid grid-cols-2 space-x-2">
                                            <div className="col-span-1 overflow-scroll hide-scrollbar" style={{height: '85vh'}}>
                                                {renderPrintableQuestionAndAnswersBlocksList()}
                                            </div>
                                            <div className="col-span-1 overflow-scroll hide-scrollbar" style={{height: '85vh'}}>
                                                {renderPrintableAttachmentBlocksList()}
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        {renderAttachmentsAndNotes()}
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </>
                    }
                </div>
            </div>
        </Body>
    </>;
};

export default TeacherViewExamPage;
