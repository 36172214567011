import {ApolloClient, ApolloLink, createHttpLink, fromPromise, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {AccessToken, TokenDocument} from "../generated/graphql";
import {onError} from "@apollo/client/link/error";
import useAuthStore from "../contexts/AuthStore";
import authStore from "../contexts/AuthStore";

const graphqlUri = process.env.NODE_ENV === "development" ? "https://api.toetssucces.nl" : "https://api.toetssucces.nl";

export const useApolloClient = (token: AccessToken | null) => {
    const httpLink = createHttpLink({uri: graphqlUri});
    const authLink = setContext((_, {headers}) => {
        return {
            headers: {
                ...headers,
                authorization: token?.accessToken ? `Bearer ${token.accessToken}` : "",
            }
        }
    });

    const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, locations, path, originalError, extensions, source}) =>
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Original Error: ${originalError}, Extensions: ${extensions}, Source: ${source}`));

            if (graphQLErrors.some(error => error.message === "expired access token" || error.message === "invalid access token")) {
                console.log("access token expired, fetching new one using refresh token: ", token?.refreshToken);

                return fromPromise(refreshToken(token?.refreshToken ?? "").catch((err) => {
                    console.log("error while refreshing token: ", err);
                    useAuthStore.getState().logout();
                }))
                    .filter((value) => Boolean(value))
                    .flatMap((newToken) => {
                        console.log("got new token! forwarding request");
                        const oldHeaders = operation.getContext().headers;
                        // modify the operation context with a new token
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: `Bearer ${newToken?.accessToken}`,
                            },
                        });

                        // retry the request, returning the new observable
                        return forward(operation);
                    });
            }

            if (graphQLErrors.some(error => error.message === "expired refresh token" || error.message === "invalid refresh token")) {
                console.log("refresh token expired, logging out");
                useAuthStore.getState().logout();
            }
        }
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);

            // Log out when forbidden
            if (networkError.message.includes("status code 403")) {
                authStore.getState().logout();
            }
        }
    });

    const client = new ApolloClient({
        link: ApolloLink.from([errorLink, authLink, httpLink]),
        cache: new InMemoryCache(),
    });

    const refreshToken = async (refreshToken: string): Promise<AccessToken | null> => {
        const result = await client.query({
            query: TokenDocument,
            variables: {
                token: refreshToken,
            }
        })

        if (result.data.token) {
            useAuthStore.setState({token: result.data.token});
            return result.data.token;
        }

        return null;
    }

    return client;
};
