import Dropdown, {DropdownOption} from "../../components/Dropdown";
import {BsChevronRight} from "react-icons/bs";
import React, {MouseEventHandler, useEffect, useState} from "react";
import {Chapter, Class, Difficulty, Paragraph, useChaptersQuery, useClassesQuery} from "../../generated/graphql";
import {CropModel} from "../../models/CropModel";

interface Props {
    dispatch: Function;
    confirm: MouseEventHandler<HTMLButtonElement>;
    loading: boolean;
    selectedChapter: Chapter | undefined;
    setSelectedChapter: Function;
    selectedParagraph: Paragraph | undefined;
    setSelectedParagraph: Function;
    selectedDifficulty: Difficulty | undefined;
    setSelectedDifficulty: Function;
    selectedClasses: Class | undefined;
    setSelectedClasses: Function;
    selectedDuration: number | undefined;
    setSelectedDuration: Function;
    questionCrop: CropModel;
    answerCrop: CropModel;
}

const durationOptions = [
    {title: "5 minuten", value: 5},
    {title: "10 minuten", value: 10},
    {title: "15 minuten", value: 15},
]

const CropForm = (props: Props) => {
    const {dispatch, confirm} = props;

    const [chapters, setChapters] = useState<Chapter[]>([]);
    const chaptersQuery = useChaptersQuery();
    const [paragraphs, setParagraphs] = useState<Paragraph[]>([]);
    const [classes, setClasses] = useState<Class[]>([]);
    const classesQuery = useClassesQuery();

    useEffect(() => setChapters(chaptersQuery?.data?.chapters as Chapter[]), [chaptersQuery]);

    useEffect(() => {
        props.setSelectedChapter(undefined);
        props.setSelectedParagraph(undefined);
        props.setSelectedDifficulty(undefined);
        props.setSelectedDuration(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedClasses]);

    useEffect(() => {
        setParagraphs(props.selectedChapter?.paragraphs as Paragraph[]);
    }, [props.selectedChapter]);

    useEffect(() => setClasses(classesQuery?.data?.classes as Class[]), [classesQuery]);

    return (
        <div>
            <h3 className="font-bold mt-2">Klassen</h3>
            <Dropdown
                selectedOption={props.selectedClasses}
                options={classes ? classes.map((c: Class) => {
                    return {title: c.name, value: c.name};
                }) : []}
                setSelectedOption={(option: DropdownOption) => {
                    if (option) {
                        const sClasses = classes.find((e: Class) => e.name === option.value);
                        props.setSelectedClasses(sClasses);
                        dispatch({key: "classes", value: sClasses});
                    }
                }}
                enabled={(!!props.questionCrop.image && (!!props.answerCrop.image)) && chapters !== undefined && chapters?.length > 0}
            />

            <h3 className="font-bold mt-2">Hoofdstuk</h3>
            <Dropdown
                options={chapters ? chapters.map((c: Chapter) => {
                    return {title: c.title, value: c.id};
                }) : []}
                selectedOption={props.selectedChapter}
                setSelectedOption={(option: DropdownOption) => {
                    if (option) {
                        const chapter = chapters.find((e: Chapter) => e.id === option.value);
                        props.setSelectedChapter(chapter)
                        dispatch({key: "chapter", value: chapter});
                    }
                }}
                enabled={props.selectedClasses !== undefined && chapters !== undefined && chapters?.length > 0}
            />

            <h3 className="font-bold mt-2">Paragraaf</h3>
            <Dropdown
                selectedOption={props.selectedParagraph}
                options={paragraphs ? paragraphs.map((c: Paragraph) => {
                    return {title: c.title, value: c.id};
                }) : []}
                setSelectedOption={(option: DropdownOption) => {
                    if (option) {
                        const paragraph = paragraphs.find((e: Paragraph) => e.id === option.value);
                        props.setSelectedParagraph(paragraph);
                        dispatch({key: "paragraph", value: paragraph});
                    }
                }}
                enabled={props.selectedChapter !== undefined}
            />

            <h3 className="font-bold mt-2">Moeilijkheidsgraad</h3>
            <Dropdown
                selectedOption={props.selectedDifficulty}
                options={Object.values(Difficulty).map((c) => {
                    return {title: c, value: c};
                })}
                setSelectedOption={(e: Difficulty) => {
                    props.setSelectedDifficulty(e);
                    dispatch({key: "difficulty", value: e});
                }}
                enabled={props.selectedParagraph !== undefined}
            />

            <h3 className="font-bold mt-2">Duur</h3>
            <Dropdown
                selectedOption={props.selectedDuration}
                options={durationOptions.map((c) => {
                    return {title: c.title, value: c.value};
                })}
                setSelectedOption={(option: DropdownOption) => {
                    const duration = durationOptions.find((e) => e.value === option?.value);
                    if (option && duration) {
                        props.setSelectedDuration(duration.value);
                        dispatch({key: "duration", value: duration.value});
                    }
                }}
                enabled={props.selectedDifficulty !== undefined}
            />

            {/* Confirm button */}
            {(props.selectedDuration && props.selectedChapter && props.selectedParagraph && props.selectedDifficulty && props.selectedClasses) && <>
                <button
                    className={`bg-gray-100 rounded px-4 py-3 flex justify-between items-center cursor-pointer w-full mt-6`}
                    onClick={confirm}
                    disabled={(!props.selectedDuration || !props.selectedChapter || !props.selectedParagraph || !props.selectedDifficulty || !props.selectedClasses) || props.loading}
                >
                    <h2 className="text-md font-bold w-full">
                        {props.loading ? (
                            <svg
                                className="animate-spin mx-auto h-5 w-full text-black inline"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : (
                            <>Som en antwoord afbakenen</>
                        )}
                    </h2>
                    <BsChevronRight/>
                </button>
            </>}
        </div>
    );
};

export default CropForm;
