import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useSubscriptionQuery} from "../../generated/graphql";
import TranslationKeys from "../../localization/translationKeys";
import LoadingWrapper from "../../components/LoadingWrapper";
import SubscriptionPackage from "../../components/SubscriptionPackage";
import CancelSubscriptionDialog from "../../components/CancelSubscriptionDialog";
import SubscriptionPackages from "../../components/SubscriptionPackages";

const ManageSubscription = () => {
    const {t} = useTranslation();

    const {data, error, loading, refetch} = useSubscriptionQuery({fetchPolicy: "network-only"});
    const [cancelOpen, setCancelOpen] = useState(false);

    const formattedCancelUsableTillDate = () => {
        if (!data?.subscription?.usableTillDateIfCancelled) return "";

        const date = new Date(data?.subscription?.usableTillDateIfCancelled);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    }

    const hasRunningSubscription = () => {
        const subscriptionData = data?.subscription
        if (!subscriptionData) return false;
        if (!subscriptionData.cancelledAt) return true;
        return subscriptionData.usableTillDateIfCancelled && new Date(subscriptionData.usableTillDateIfCancelled) > new Date();
    }

    const bodyForSubscription = (
        <div>
            <CancelSubscriptionDialog
                isOpen={cancelOpen}
                close={() => setCancelOpen(false)}
                usableTill={formattedCancelUsableTillDate()}
                subscriptionCancelled={() => refetch()}
            />
            {error && <p className="text-red-500">{error.message}</p>}

            <div className="flex items-center text-xl font-semibold gap-1 mb-4">
                <img src="/img/icon_figure_cylinder.svg" alt="Abonnement" className="h-6"/>
                {t(TranslationKeys.subscriptionTitle)}
            </div>

            <div className="mb-6 text-sm">
                {t(TranslationKeys.currentSubscription)}
            </div>

            {data?.subscription?.package && (
                <SubscriptionPackage showBuyButton={false} p={data.subscription.package} isHighlighted/>
            )}

            {data?.subscription?.cancelledAt ? (
                <div className="mt-4 p-4 rounded-lg bg-yellow-300">
                    {t(TranslationKeys.subscriptionCancelled, {date: formattedCancelUsableTillDate()})}
                </div>
            ) : (
                <button
                    className="mt-6 btn-danger-alt"
                    onClick={() => setCancelOpen(true)}
                >
                    {t(TranslationKeys.cancelSubscription)}
                </button>
            )}

        </div>
    );

    const bodyForNoSubscription = (
        <div>
            <h2 className="mb-4">{t(TranslationKeys.noSubscription)}</h2>
            <SubscriptionPackages/>
        </div>
    )

    return (
        <LoadingWrapper isLoading={loading}>
            {hasRunningSubscription() ? bodyForSubscription : bodyForNoSubscription}
        </LoadingWrapper>
    );
}

export default ManageSubscription
