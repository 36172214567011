import React, {ReactNode} from "react";
import {FaSpinner} from "react-icons/fa";


const LoadingWrapper = ({isLoading, children}: { isLoading: boolean, children: ReactNode }) => {
    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center h-full w-full">
                    <FaSpinner className="animate-spin"/>
                </div>
            ) : children}
        </>
    );
}

export default LoadingWrapper;
