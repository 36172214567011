import { create } from "zustand";
import { persist } from "zustand/middleware";
import {AccessToken} from "../generated/graphql";

interface AuthStore {
    token: AccessToken | null;
    setToken: (token: AccessToken) => void;
    logout: () => void;
}

const useAuthStore = create(persist<AuthStore>(
    (set, get) => ({
        token: null,
        setToken: (token) => set({ token }),
        logout: () => set({ token: null }),
    }),
    {
        name: 'auth-storage',
    }
));

export default useAuthStore;
