import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {useTranslation} from "react-i18next";
import TranslationKeys from "../../localization/translationKeys";
import {useNavigate, useParams} from "react-router-dom";
import ImageUpload from "../../components/ImageUpload";
import PopupDialog from "../../components/PopupDialog";
import {MdUploadFile} from "react-icons/md";
import {
    Exam,
    useAnswerExamMutation,
    useExamQuery,
    useFinishExamMutation,
    useFinishUploadMutation,
    useRequestUploadAnswerMutation
} from "../../generated/graphql";
import {uploadFile} from "../../providers/uploadFile";
import {FaSpinner} from "react-icons/fa";

const StudentUploadAnswersPage = () => {
    const {t} = useTranslation();
    const {examIdentifier} = useParams();
    const navigate = useNavigate();

    const [requestUploadAnswerMutation] = useRequestUploadAnswerMutation();
    const [finishUploadMutation] = useFinishUploadMutation();
    const [answerExamMutation] = useAnswerExamMutation();
    const [finishExamMutation] = useFinishExamMutation();

    const [loading, setLoading] = useState(false);
    const [handInEnabled, setHandInEnabled] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const [files, setFiles] = useState<File[]>([]);
    useEffect(() => setHandInEnabled(files.length > 0), [files]);

    const [exam, setExam] = useState<Exam>();
    useEffect(() => {
        // Update uploaded files
        exam?.studentAnswers.map(async (sa) => {
            const res = await fetch(sa.imageURL);
            const blob = await res.blob();
            const file = new File([blob], `${sa.id}.png`, {type: blob.type});
            setFiles((fs) => {
                const newFs = [...fs];
                newFs.push(file);
                return newFs;
            });
        });
    }, [exam]);

    const examQuery = useExamQuery({
        variables: {
            examId: examIdentifier || ""
        }
    });
    useEffect(() => {
        setLoading(examQuery.loading);
        if (examQuery.error) navigate('/404');
        else setExam(examQuery.data?.exam as Exam);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examQuery]);

    const handleUpload = () => {
        setShowConfirmationDialog(true);
    };

    const uploadAnswers = async () => {
        setShowConfirmationDialog(false);
        setLoading(true);

        // Upload answers
        for await (const f of files) {
            try {
                await uploadAnswer(f)
            } catch (e) {
                console.log(e);
            }
        }

        // Finish exam
        const {errors} = await finishExamMutation({
            variables: {
                examId: examIdentifier || ""
            }
        });
        if (errors) throw new Error("Uploaded failed (Answer)");
        setLoading(false);

        // Navigate back to home
        navigate('/');
    };

    const uploadAnswer = async (file: File) => {
        const {data, errors} = await requestUploadAnswerMutation();
        if (errors) throw new Error("Uploaded failed (Answer)");

        const {uploadURL, objectId} = data?.requestUpload || {};
        if (!uploadURL || !objectId) throw new Error('No uploadUrl or objectId (Answer)');

        await uploadFile(file, uploadURL);
        await finishUpload(file, objectId, 'Answer');
    };

    const finishUpload = async (file: File, objectId: string, tag: string) => {
        const {errors} = await finishUploadMutation({variables: {objectId}});
        if (errors) throw new Error(`Finish upload failed (${tag})`);
        await answerExam(objectId);
    };

    const answerExam = async (objectId: string) => {
        const {errors} = await answerExamMutation({
            variables: {
                examId: examIdentifier || "",
                objectId: objectId
            }
        });
        if (errors) throw new Error(`Answer exam failed.`);
    };

    return <>
        <Header
            title={`${t(TranslationKeys.exam)} ${(new Date(exam?.createdAt).toLocaleDateString())}`}
            backHref={!loading ? `/student/view/${examIdentifier}` : ""}
            compact={true}
            headerAction={{
                title: t(TranslationKeys.handInAnswers),
                onClick: () => handleUpload(),
                icon: <MdUploadFile/>,
                loading: loading,
                enabled: !loading && handInEnabled
            }}
        />
        <Body>
            <div className="fixed bottom-24 md:bottom-8 left-0 right-0">
                <button
                    disabled={loading}
                    className="btn-main !w-max mx-auto bottom-4 mt-8 !px-6 flex items-center"
                    onClick={() => handleUpload()}
                >
                    {loading && <FaSpinner className="animate-spin"/>}
                    {t(TranslationKeys.handInAnswers)}
                    <MdUploadFile/>
                </button>
            </div>

            <p>{t(TranslationKeys.handInAnswersInfo)}</p>

            <div className="mt-4">
                <ImageUpload
                    enabled={!loading}
                    files={files}
                    setFiles={setFiles}
                    deleteEnabled={true}
                    sortingEnabled={true}
                />
            </div>

            <PopupDialog
                show={showConfirmationDialog}
                setShow={setShowConfirmationDialog}
                title={t(TranslationKeys.areYouSure)}
                message={t(TranslationKeys.handInAnswersConfirmationDescription)}
                yesButtonTitle={t(TranslationKeys.handIn)}
                yesButtonAction={async () => await uploadAnswers()}
                noButtonTitle={t(TranslationKeys.back)}
                noButtonAction={() => setShowConfirmationDialog(false)}
            />
        </Body>
    </>;
};

export default StudentUploadAnswersPage;
