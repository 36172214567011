import React, {useEffect} from "react";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import TranslationKeys from "../../localization/translationKeys";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {SubscriptionStatus, useSubscriptionStatusQuery} from "../../generated/graphql";
import LoadingWrapper from "../../components/LoadingWrapper";

const PaymentStatus = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data, loading, error, refetch} = useSubscriptionStatusQuery();

    const toHome = async () => {
        navigate("/parent");
    }

    // Poll status if it isn't active
    useEffect(() => {
        const interval = setInterval(async () => {
            if (data?.subscriptionStatus !== SubscriptionStatus.Active) {
                await refetch();
            }
        }, 15 * 1000);
        return () => clearInterval(interval);
    }, [data, refetch]);

    const title = () => {
        if (loading) return t(TranslationKeys.loading);
        if (error) return t(TranslationKeys.subscriptionTitle);
        switch (data?.subscriptionStatus) {
            case SubscriptionStatus.Active:
                return t(TranslationKeys.subscriptionActiveTitle);
            case SubscriptionStatus.Pending:
                return t(TranslationKeys.subscriptionPendingTitle);
            case SubscriptionStatus.None:
                return t(TranslationKeys.subscriptionNoneTitle);
            default:
                return t(TranslationKeys.subscriptionNoneTitle);
        }
    }

    const text = () => {
        if (error) return t(TranslationKeys.genericError);
        switch (data?.subscriptionStatus) {
            case SubscriptionStatus.Active:
                return t(TranslationKeys.subscriptionActiveText);
            case SubscriptionStatus.Pending:
                return t(TranslationKeys.subscriptionPendingText);
            case SubscriptionStatus.None:
                return t(TranslationKeys.subscriptionNoneText);
            default:
                return t(TranslationKeys.subscriptionNoneText);
        }
    }

    return (
        <>
            <Header
                title={title()}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />

            <Body>
                <LoadingWrapper isLoading={loading}>
                    <p className="whitespace-pre-line mb-12">{text()}</p>

                    {data?.subscriptionStatus === SubscriptionStatus.Active && (
                        <button
                            className="btn-main"
                            onClick={toHome}
                        >
                            {t(TranslationKeys.toHome)}
                        </button>
                    )}
                </LoadingWrapper>
            </Body>
        </>
    );
};

export default PaymentStatus;
