import LoadingWrapper from "./LoadingWrapper";
import TranslationKeys from "../localization/translationKeys";
import SubscriptionPackage from "./SubscriptionPackage";
import React from "react";
import {usePackagesQuery} from "../generated/graphql";
import {useTranslation} from "react-i18next";

const SubscriptionPackages = () => {
    const {t} = useTranslation();
    const {data, loading, error} = usePackagesQuery();

    return (
        <LoadingWrapper isLoading={loading}>
            {error && <p className="text-red-500">{t(TranslationKeys.fetchError)}</p>}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {data?.packages?.map((p, index) => (
                    <SubscriptionPackage showBuyButton key={p.id} p={p} isHighlighted={index === 1}/>
                ))}
            </div>
        </LoadingWrapper>
    )
}

export default SubscriptionPackages;
