import {Dialog} from "@headlessui/react";
import TranslationKeys from "../localization/translationKeys";
import {FaSpinner} from "react-icons/fa";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useCancelSubscriptionMutation} from "../generated/graphql";

const CancelSubscriptionDialog = ({isOpen, close, usableTill, subscriptionCancelled}: {
    isOpen: boolean;
    close: () => void;
    subscriptionCancelled: () => void;
    usableTill: string;
}) => {
    const {t} = useTranslation();
    const [cancelSubscription, {data, loading, error}] = useCancelSubscriptionMutation();

    const cancel = async () => {
        await cancelSubscription();
    }

    useEffect(() => {
        if (!data) return;
        close();
        subscriptionCancelled();
    }, [data, close, subscriptionCancelled]);

    const errorMessage = error ? t(TranslationKeys.genericError) : undefined;

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
            }}
            className="fixed z-50 inset-0 overflow-y-auto"
        >
            <div className="mx-2 flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50"/>

                <div className="relative bg-white rounded max-w-sm mx-auto p-6">
                    <Dialog.Title className="font-bold text-left">{t(TranslationKeys.areYouSure)}</Dialog.Title>
                    <p className="py-2">{t(TranslationKeys.cancelSubscriptionText, {date: usableTill})}</p>

                    {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
                    <div className="flex flex-col justify-between gap-2">
                        <button
                            disabled={loading}
                            className="bg-none bg-gray-100 hover:bg-gray-200 h-full text-gray-800 rounded p-2 mt-4 w-full outline-none"
                            onClick={close}
                        >
                            {t(TranslationKeys.cancel)}
                        </button>
                        <button
                            disabled={loading}
                            className="btn-danger"
                            onClick={cancel}>
                            {loading && <FaSpinner className="animate-spin"/>}
                            {t(TranslationKeys.cancelSubscription)}
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CancelSubscriptionDialog;
