import React from "react";
import {Dialog} from '@headlessui/react'

interface Props {
    show: boolean;
    setShow: Function;
    title: string;
    message: string;
    yesButtonTitle: string;
    yesButtonAction: Function;
    noButtonTitle: string;
    noButtonAction: Function;
}

const PopupDialog = ({
                         show,
                         setShow,
                         title,
                         message,
                         yesButtonTitle,
                         yesButtonAction,
                         noButtonTitle,
                         noButtonAction
                     }: Props) => {
    return <Dialog
        open={show}
        onClose={() => {}}
        className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50"/>

            <div className="relative bg-white rounded max-w-sm mx-auto p-6">
                <Dialog.Title className="font-bold text-center">{title}</Dialog.Title>
                <p className="py-2">{message}</p>
                <div className="flex justify-between gap-2">
                    <button className="bg-red-500 text-white rounded p-2 mt-4 w-full outline-none" onClick={() => yesButtonAction()}>
                        {yesButtonTitle}
                    </button>
                    <button className="bg-gray-400 text-white rounded p-2 mt-4 w-full outline-none" onClick={() => noButtonAction()}>
                        {noButtonTitle}
                    </button>
                </div>
            </div>
        </div>
    </Dialog>;
};

export default PopupDialog;