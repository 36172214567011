import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import TranslationKeys from "../../localization/translationKeys";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {Class, useClassesQuery, useRegisterStudentMutation} from "../../generated/graphql";
import Dropdown, {DropdownOption} from "../../components/Dropdown";
import {FaSpinner} from "react-icons/fa";

const RegisterStudent = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [classes, setClasses] = useState<Class[]>([]);
    const classesQuery = useClassesQuery();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectedClass, setSelectedClass] = useState<Class>();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [uiError, setUiError] = useState("");

    useEffect(() => setClasses(classesQuery?.data?.classes as Class[]), [classesQuery]);
    const [register, {loading, data, error}] = useRegisterStudentMutation();

    const allFieldsFilled = !!(firstName && lastName && email && password && selectedClass);

    const toNextStep = async () => {
        setUiError("");

        await register({
            variables: {
                email,
                password,
                firstName,
                lastName,
                classId: selectedClass!.id
            }
        });
    }

    useEffect(() => {
        if (!data && !error) return;

        if (error) {
            if (error.message.includes("EMAIL_ALREADY_EXISTS")) {
                return setUiError(t(TranslationKeys.emailExistsError));
            }
            return setUiError(t(TranslationKeys.registerStudentFailed));
        }

        if (data) {
            navigate('/register/subscription');
        }
    }, [data, error, navigate, t]);

    return (
        <>
            <Header
                title={t(TranslationKeys.studentInfoTitle)}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />
            <Body>
                <p className="whitespace-pre-line">{t(TranslationKeys.studentInfoDescription)}</p>
                <div className="flex flex-col gap-4 mt-4">

                    <div className="flex gap-4">
                        <div className="w-full">
                            <div className="app-input-label">{t(TranslationKeys.firstName)}</div>
                            <input
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="app-input"
                                type="text"
                                placeholder={t(TranslationKeys.firstName)}
                            />
                        </div>
                        <div className="w-full">
                            <div className="app-input-label">{t(TranslationKeys.lastName)}</div>
                            <input
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="app-input"
                                type="text"
                                placeholder={t(TranslationKeys.lastName)}
                            />
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="app-input-label">{t(TranslationKeys.email)}</div>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="app-input"
                            type="email"
                            placeholder={t(TranslationKeys.emailStudent)}
                        />
                    </div>

                    <div className="w-full">
                        <div className="app-input-label">{t(TranslationKeys.password)}</div>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="app-input"
                            type="password"
                            placeholder={t(TranslationKeys.passwordStudent)}
                        />
                    </div>

                    <Dropdown
                        label={t(TranslationKeys.class)}
                        selectedOption={selectedClass}
                        options={classes ? classes.map((c: Class) => {
                            return {title: c.name, value: c.name};
                        }) : []}
                        setSelectedOption={(option: DropdownOption) => {
                            if (option) {
                                const s = classes.find((e: Class) => e.name === option.value);
                                setSelectedClass(s);
                            }
                        }}
                        enabled
                    />

                    {uiError && <p className="flex bg-red-300 text-red-900 p-4 rounded-lg">{uiError}</p>}

                    <button
                        disabled={loading || !allFieldsFilled}
                        className="btn-main"
                        onClick={toNextStep}
                    >
                        {loading && <FaSpinner className="animate-spin"/>}
                        {t(TranslationKeys.continue)}
                    </button>
                </div>
            </Body>
        </>
    );
};

export default RegisterStudent;
