export const getImageData = async (source: string, y: number, height: number, yMargin: number, hMargin: number): Promise<ImageData> => {
    const image = new Image();
    image.src = source;

    await new Promise<void>(resolve => image.addEventListener('load', () => resolve()));

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');

    if (context) {
        context.drawImage(image, 0, 0, image.width, image.height);
        return context.getImageData(0, y + yMargin, image.width, height + hMargin);
    }

    throw new Error("Could not get image data from source.");
};