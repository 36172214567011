import React from "react";
import {AiOutlinePlus} from "react-icons/ai";
import {IoClose} from "react-icons/io5";

interface Props {
    title: string,
    enabled?: boolean,
    selected: boolean,
    onSelect: Function,
}

const SelectBlock = ({title, enabled, selected, onSelect}: Props) => {
    return <button
        className={`z-0 flex justify-between items-center px-4 py-2 rounded w-full shadow border ${enabled ? 'cursor-pointer' : 'cursor-default opacity-60'}`}
        onClick={() => enabled && onSelect()}>
        <div className={`font-semibold ${selected ? 'text-[#79b037]' : ''}`}>
            {title}
        </div>
        <div className={`p-2 rounded-full`}>
            {selected ? <IoClose/> : <AiOutlinePlus/>}
        </div>
    </button>;
};

export default SelectBlock;