import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import TranslationKeys from "../../localization/translationKeys";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {useRegisterParentMutation} from "../../generated/graphql";
import useRegistrationStore from "../../contexts/RegistrationStore";
import {FaInfoCircle, FaSpinner} from "react-icons/fa";

const Register = () => {
    const setRegInfo = useRegistrationStore(state => state.setInfo);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [uiError, setUiError] = useState("");
    const [register, {loading, data, error}] = useRegisterParentMutation();

    const toNextStep = async () => {
        setUiError("");

        await register({
            variables: {
                email,
                password,
                firstName,
                lastName
            }
        });
    }

    useEffect(() => {
        if (!data && !error) return;

        if (error) {
            if (error.message.includes("EMAIL_ALREADY_EXISTS")) {
                return setUiError(t(TranslationKeys.emailExistsError));
            }

            setUiError(t(TranslationKeys.registerFailed));

            return;
        }

        if (data) {
            setRegInfo({
                userId: data.registerParent.id,
                email,
                password
            });
            navigate('/register/verify-email');
        }
    }, [data, error, setRegInfo, navigate, t, email, password]);

    const allFieldsFilled = email && password && firstName && lastName;

    return (
        <>
            <Header
                title={t(TranslationKeys.register)}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />
            <Body>
                <div className="pb-4">
                    {t(TranslationKeys.alreadyHaveAccount)} <a href="/login"
                                                               className="underline">{t(TranslationKeys.loginHere)}</a>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="bg-[#e7fbff] text-[#14b3d4] flex justify-start items-center gap-3 p-3 rounded">
                        <FaInfoCircle className="text-xl"/>
                        {t(TranslationKeys.registerTextParent)}
                    </div>
                    <div className="bg-[#e7fbff] text-[#14b3d4] flex justify-start items-center gap-3 p-3 rounded">
                        <FaInfoCircle className="text-xl"/>
                        {t(TranslationKeys.registerTextStudent)}
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-4">
                    <div className="flex gap-4">
                        <div className="w-full">
                            <div className="app-input-label">{t(TranslationKeys.firstName)}</div>
                            <input
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="app-input"
                                type="text"
                                placeholder={t(TranslationKeys.firstName)}
                            />
                        </div>
                        <div className="w-full">
                            <div className="app-input-label">{t(TranslationKeys.lastName)}</div>
                            <input
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="app-input"
                                type="text"
                                placeholder={t(TranslationKeys.lastName)}
                            />
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="app-input-label">{t(TranslationKeys.email)}</div>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="app-input"
                            type="email"
                            placeholder={t(TranslationKeys.email)}
                        />
                    </div>

                    <div className="w-full">
                        <div className="app-input-label">{t(TranslationKeys.password)}</div>
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="app-input"
                            type="password"
                            placeholder={t(TranslationKeys.password)}
                        />
                    </div>

                    {uiError && <p className="flex bg-red-300 text-red-900 p-4 rounded-lg">{uiError}</p>}

                    <button
                        disabled={loading || !allFieldsFilled}
                        className="btn-main"
                        onClick={toNextStep}
                    >
                        {loading && <FaSpinner className="animate-spin"/>}
                        {t(TranslationKeys.continue)}
                    </button>
                </div>
            </Body>
        </>
    );
};

export default Register;
