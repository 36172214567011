import {ReactElement, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {UserType} from "../generated/graphql";
import useAuthStore from "../contexts/AuthStore";

interface Props {
    component: ReactElement;
    scope: UserType
}

const ProtectedPage = ({component, scope}: Props) => {
    const token = useAuthStore(state => state.token);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token?.accessToken || token?.scope !== scope) {
            navigate(`/login?scope=${scope.toLowerCase()}`);
        }
    }, [token, navigate, scope]);

    return component;
};

export default ProtectedPage;
