import { BiUpload, BiTrash } from "react-icons/bi";

interface Props {
  enabled: boolean;
  setFile: Function;
  label: string;
  file: File | null;
}

const PdfUpload = ({ enabled, setFile, label, file }: Props) => {
  return (
    <>
      <label className="block hover:cursor-pointer">
        <div className="inline-flex justify-center items-center w-full bg-white mt-1 px-3 py-3 border bg-gray-50 rounded">
          <span className="w-full rounded text-md text-gray-500 text-md">
            {label}
          </span>
          {file ? (
            <BiTrash
              className="text-gray-500"
              onClick={(e) => {
                e.preventDefault();
                setFile(null);
              }}
            />
          ) : (
            <BiUpload className="text-gray-500" />
          )}
        </div>
        <input
          disabled={!enabled}
          type="file"
          className="w-full hidden"
          accept="pdf/*"
          onChange={(e) => {
            if (e?.target?.files?.length) {
              let newFiles = Array.from(e.target.files);
              setFile(newFiles[0]);
            }
          }}
        />
      </label>
    </>
  );
};

export default PdfUpload;
