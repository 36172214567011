interface Props {
  pages: number;
  setPage: Function;
  page: number;
}

const PageNavigation = ({ pages, setPage, page }: Props) => {
  if (!pages) {
    return null;
  }

  return (
    <div className="flex justify-between mt-4" style={{ width: "595px" }}>
      <button
        disabled={page <= 0}
        className="px-4 py-2 md:bg-lime-500 lg:bg-gray-300 md:rounded"
        onClick={() => {
          setPage(page - 1);
        }}
      >
        Vorige
      </button>
      {/* NOTE: assumes tracking of pages uses base 0, so for the user display page + 1 */}
      <h1>{`${page + 1} / ${pages}`}</h1>
      <button
        disabled={page >= pages - 1}
        className="px-4 py-2 md:bg-lime-500 lg:bg-gray-300 md:rounded"
        onClick={() => {
          setPage(page + 1);
        }}
      >
        Volgende
      </button>
    </div>
  );
};

export default PageNavigation;
