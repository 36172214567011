import React, {useEffect, useRef, useState} from "react";
import {GrEdit, GrStop} from "react-icons/gr";
import {useReactToPrint} from "react-to-print";
import PrintHeader from "./PrintHeader";
import {ReactPainter} from 'react-painter';
import {FaPrint} from "react-icons/fa";

interface Props {
    id: string;
    imageURL: string;
    title?: string;
    drawable?: boolean;
    data?: string | null;
    setData?: Function;
    withBorder?: boolean;
    noPrint?: boolean;
}

const ImageBlock = ({id, imageURL, title, drawable, data, setData, withBorder, noPrint}: Props) => {
    const [printWithHeader, setPrintWithHeader] = useState(false);

    const painterRef = useRef<ReactPainter>(null);
    const printRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => printRef.current || null,
        onAfterPrint: () => setPrintWithHeader(false)
    });

    useEffect(() => {
        if (printWithHeader) {
            handlePrint();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printWithHeader]);

    const [image, setImage] = useState<File>();
    useEffect(() => {
        (async () => {
            await loadImage()
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadImage = async () => {
        const response = await fetch(data ? data : imageURL);
        const blob = await response.blob();
        const file = new File([blob], 'image.png', {type: blob.type});
        setImage(file);
    };

    const [isDrawing, setIsDrawing] = useState(false);
    useEffect(() => {
        if (drawable) {
            painterRef?.current?.handleSetLineWidth(isDrawing ? 2 : 0.001);

            // Enable/disable scrolling when drawing
            document.body.style.overflow = isDrawing ? "hidden" : "visible";
        }
    }, [isDrawing, drawable])

    return <div id={id} ref={printRef}
                className={`bg-white break-inside-avoid-page ${drawable ? '' : (withBorder ? 'border-t' : '')}`}>
        {printWithHeader && <>
            <PrintHeader borderBottom={true}/>
        </>}
        <div className="flex justify-between items-center pt-4">
            {title && <h2 className="font-bold">{title}</h2>}
            <div className="flex">
                {drawable && <>
                    {isDrawing
                        ? <GrStop className={`${!noPrint && 'mr-4'}`} onClick={() => {
                            setIsDrawing(false);
                            painterRef?.current?.handleSave();
                        }}/>
                        : <GrEdit className={`${!noPrint && 'mr-4'}`} onClick={() => setIsDrawing(true)}/>
                    }
                </>}
                {!noPrint && <FaPrint className="cursor-pointer no-print" onClick={() => setPrintWithHeader(true)}/>}
            </div>
        </div>
        {drawable ? (
            <div className="border bg-gray-50 flex justify-center">
                {image && (
                    <ReactPainter
                        image={image}
                        width={(document?.getElementById('body')?.clientWidth || 50) / 2 - 50}
                        ref={painterRef}
                        onSave={blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = function () {
                                const base64data = reader.result;
                                if (setData) setData(base64data);
                            }
                        }}
                        render={({canvas}) => (
                            <div>
                                <div>{canvas}</div>
                            </div>
                        )}
                        initialColor={'#FF0000'}
                        initialLineWidth={0.001}
                    />
                )}
            </div>
        ) : (
            <img className="py-4" alt={title} src={imageURL}/>
        )}
    </div>;
};

export default ImageBlock;
