import React from "react";
import {Outlet} from "react-router-dom";

const TeacherLayout = () => {
    return <>
        <div className="bg-white lg:max-h-screen m-auto h-screen overflow-hidden">
            <Outlet/>
        </div>
    </>
};

export default TeacherLayout;
