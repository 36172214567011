import React, {Fragment, useEffect, useState} from "react";
import {Listbox} from '@headlessui/react'
import {useTranslation} from "react-i18next";
import TranslationKeys from "../localization/translationKeys";
import {IoChevronDown} from "react-icons/io5";

export interface DropdownOption {
    title: string;
    value: any;
}

interface Props {
    label?: string;
    enabled: boolean;
    options: DropdownOption[];
    selectedOption: any;
    setSelectedOption: Function;
    emptyLabel?: string;
}

const Dropdown = ({label, enabled, options, selectedOption, setSelectedOption, emptyLabel}: Props) => {
    const {t} = useTranslation();

    const [selectedOptionInternal, setSelectedOptionInternal] = useState<DropdownOption>();
    useEffect(() => {
        setSelectedOption(selectedOptionInternal);
    }, [selectedOptionInternal, setSelectedOption]);
    useEffect(() => {
        if (!selectedOption) setSelectedOptionInternal(undefined);
    }, [selectedOption])

    return (
        <div className="flex flex-col">
            {label && <div className="app-input-label">{label}</div>}
            <Listbox value={selectedOptionInternal} onChange={setSelectedOptionInternal} disabled={!enabled}>
                <div className="relative">
                    <Listbox.Button
                        className={`app-input flex justify-between w-full items-center gap-4 ${enabled ? 'bg-white' : 'bg-gray-300'}`}>
                        {selectedOptionInternal ? selectedOptionInternal.title : (emptyLabel || t(TranslationKeys.selectValue))}
                        <IoChevronDown/>
                    </Listbox.Button>
                    <Listbox.Options className="absolute border rounded bg-white shadow-lg z-50 w-full">
                        {options.map((option, idx) => (
                            <Listbox.Option key={idx} value={option} as={Fragment}>
                                {({active}) => (
                                    <li className={`flex items-center gap-2 px-4 py-2 cursor-pointer rounded ${active ? 'bg-app-primary text-white' : 'bg-white'}`}>
                                        {option.title}
                                    </li>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
        </div>
    );
};

export default Dropdown;
