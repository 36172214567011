import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import TranslationKeys from "../../localization/translationKeys";
import Body from "../../components/Body";
import {useTranslation} from "react-i18next";
import ExamBlock from "../../components/ExamBlock";
import {useNavigate} from "react-router-dom";
import {
    Exam,
    ExamStatus,
    Teacher,
    useAssessableExamsQuery,
    useExamAssessorLazyQuery,
    useStartAssessingExamMutation,
    useTeacherLazyQuery,
} from "../../generated/graphql";
import PopupDialog from "../../components/PopupDialog";
import {IoIosCheckmarkCircle, IoMdPerson} from "react-icons/io";
import TabComponent from "../../components/Tabs";
import Spinner from "../../components/Spinner";
import {BiNotification} from "react-icons/bi";

enum Tab {
    New = 0,
    Checked = 1,
    Profile = 2,
}

const TeacherHomePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const [activeTab, setActiveTab] = useState(Tab.New);

    const [profile, setProfile] = useState<Teacher>();
    const [teacherQuery] = useTeacherLazyQuery({fetchPolicy: "network-only"});
    useEffect(() => {
        (async () => {
            // weird hack... ugly... but it works
            const data = await teacherQuery();
            setProfile(data?.data?.teacher)
        })();
    }, [teacherQuery]);

    const [startAssessingExam] = useStartAssessingExamMutation();

    const [assessableExams, setAssessableExams] = useState<Exam[]>([]);
    const examsQuery = useAssessableExamsQuery({fetchPolicy: "network-only"});

    useEffect(() => {
        setLoading(examsQuery.loading);
        setAssessableExams((examsQuery?.data?.assessableExams || []) as Exam[])
    }, [examsQuery]);

    // Refresh exams every 30 seconds
    useEffect(() => {
        const interval = setInterval(async () => {
            await examsQuery.refetch();
        }, 30 * 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [examAssessorQuery] = useExamAssessorLazyQuery();
    const canAssess = async (examId: string): Promise<boolean> => {
        const {error} = await examAssessorQuery({variables: {examId}});
        return !error;
    };

    const [selectedExam, setSelectedExam] = useState<Exam>();
    const handleClick = async (exam: Exam) => {
        if (!exam.assessor) {
            setShowConfirmationDialog(true);
        } else {
            await confirmExam();
        }
        setSelectedExam(exam);
    };
    const handleClickConfirmed = async () => {
        await confirmExam();
    };
    const confirmExam = async () => {
        if (!selectedExam) return;

        // 1. Check if teacher has permissions and the exam is not being checked by someone else
        const check = await canAssess(selectedExam.id);
        if (!check) return;

        // 2. Start assessing exam
        if (!selectedExam.assessor) {
            const {errors} = await startAssessingExam({
                variables: {
                    examId: selectedExam.id
                }
            });
            if (errors) return;
        }

        // 3. Navigate to exam
        navigate(`/teacher/view/${selectedExam.id}`, {replace: true});
    };

    const buildTabs = () => {
        const tabs = [
            {id: Tab.New.toString(), icon: <BiNotification/>, translationKey: TranslationKeys.new},
            {id: Tab.Checked.toString(), icon: <IoIosCheckmarkCircle/>, translationKey: TranslationKeys.checked},
            {id: Tab.Profile.toString(), icon: <IoMdPerson/>, translationKey: TranslationKeys.profile},
        ];

        return <TabComponent
            activeTab={activeTab.toString()}
            setActiveTab={(t) => setActiveTab(parseInt(t))}
            t={t}
            tabs={tabs}
        />
    }

    const newExams = assessableExams.filter(ae => ae.status === ExamStatus.ReadyForAssessor);
    const checkedExams = assessableExams.filter(ae => ae.status === ExamStatus.IsAssessed || ae.status === ExamStatus.BeingAssessed);

    return (
        <>
            <PopupDialog
                show={showConfirmationDialog}
                setShow={setShowConfirmationDialog}
                title={t(TranslationKeys.areYouSure)}
                message={t(TranslationKeys.claimExamDescription)}
                yesButtonTitle={t(TranslationKeys.startAssessing)}
                yesButtonAction={async () => await handleClickConfirmed()}
                noButtonTitle={t(TranslationKeys.back)}
                noButtonAction={() => setShowConfirmationDialog(false)}
            />
            <Header
                titleLabel={t(TranslationKeys.welcomeBack)}
                title={`${profile?.firstName} ${profile?.lastName}`}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />
            <Body>
                {buildTabs()}
                {loading
                    ? <Spinner/>
                    : <div>
                        {activeTab === Tab.New && (
                            <div>
                                <div className="flex items-center text-xl font-semibold gap-2 mb-4">
                                    <img src="/img/icon_figure_rectangle.svg" alt="Profiel" className="h-6"/>
                                    {t(TranslationKeys.new)}
                                </div>
                                {newExams.length > 0
                                    ? <>
                                        {newExams.map((exam, idx) => {
                                            return (
                                                <div key={idx} className={`${idx > 0 && "mt-2"}`}>
                                                    <ExamBlock
                                                        exam={exam}
                                                        onClick={async () => await handleClick(exam)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                    : <i>
                                        Er zijn (nog) geen toetsen beschikbaar om te beoordelen.
                                    </i>
                                }
                            </div>
                        )}

                        {activeTab === Tab.Checked && (
                            <div>
                                <div className="flex items-center text-xl font-semibold gap-2 mb-4">
                                    <img src="/img/icon_figure_cylinder.svg" alt="Profiel" className="h-6"/>
                                    {t(TranslationKeys.checked)}
                                </div>
                                {checkedExams.length > 0
                                    ? <>
                                        {checkedExams.map((exam, idx) => {
                                            return (
                                                <div key={idx} className={`${idx > 0 && "mt-2"}`}>
                                                    <ExamBlock
                                                        exam={exam}
                                                        onClick={async () => await handleClick(exam)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                    : <i>
                                        Je hebt nog geen toetsen beoordeeld.
                                    </i>
                                }
                            </div>
                        )}

                        {activeTab === Tab.Profile && (
                            <div>
                                <div className="flex items-center text-xl font-semibold gap-2 mb-4">
                                    <img src="/img/icon_figure_stairs.svg" alt="Profiel" className="h-6"/>
                                    {t(TranslationKeys.profile)}
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="text-center">
                                        <h1 className="text-xl font-bold">{`${profile?.firstName} ${profile?.lastName}`}</h1>
                                        <h2>{t(TranslationKeys.classes)} {profile?.classes.map(c => c.name).join(", ")}</h2>
                                    </div>

                                    <button className="btn-danger-alt mt-4" onClick={() => navigate('/logout')}>
                                        {t(TranslationKeys.logout)}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </Body>
        </>
    );
};

export default TeacherHomePage;
