import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import TranslationKeys from "../../localization/translationKeys";
import Header from "../../components/Header";
import Body from "../../components/Body";
import {useLoginLazyQuery, UserType, useVerifyEmailMutation} from "../../generated/graphql";
import useRegistrationStore from "../../contexts/RegistrationStore";
import useAuthStore from "../../contexts/AuthStore";
import {FaSpinner} from "react-icons/fa";

const VerifyEmail = () => {
    const setToken = useAuthStore(state => state.setToken);
    const token = useAuthStore(state => state.token);
    const regInfo = useRegistrationStore(state => state.info);
    const clearRegInfo = useRegistrationStore(state => state.clear);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [code, setCode] = useState("");
    const [uiError, setUiError] = useState("");

    const [verifyEmail, {loading, data, error}] = useVerifyEmailMutation();
    const [loginQuery, loginState] = useLoginLazyQuery();

    const userId = regInfo?.userId || token?.userId;

    useEffect(() => {
        if (!userId) {
            navigate('/register');
        }
    }, [navigate, userId]);

    const toNextStep = async () => {
        await verifyEmail({variables: {userID: userId!, code}});
    }

    useEffect(() => {
        if (!data && !error) return;

        if (error) {
            setUiError(t(TranslationKeys.verifyEmailFailed));
            return;
        }

        if (data && data.verifyEmail) {
            // If user is already logged in,
            if (token) return navigate('/register/add-student');

            // Verification complete, now log the user in
            loginQuery({
                variables: {
                    email: regInfo!.email,
                    password: regInfo!.password,
                    preferredScope: UserType.Parent
                }
            });
        } else {
            setUiError(t(TranslationKeys.verifyEmailFailed));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, navigate, t, regInfo, loginQuery]);

    useEffect(() => {
        const {data, error} = loginState;
        if (!data && !error) return;

        if (data?.login) {
            setToken(data.login);
            clearRegInfo();
            navigate('/register/add-student');
            return;
        }

        // Error
        setUiError(t(TranslationKeys.verifyEmailFailed));
    }, [loginState, navigate, setToken, t, clearRegInfo]);


    return (
        <>
            <Header
                title={t(TranslationKeys.emailVerificationTitle)}
                compact={false}
                rightComponent={<div>
                    <img src="/img/logo_min.png" alt="Logo min" className="md:hidden h-10"/>
                    <img src="/img/logo_full.png" alt="Toetssucces logo" className="hidden md:block h-10 mx-auto"/>
                </div>}
            />
            <Body>
                <p className="whitespace-pre-line">{t(TranslationKeys.emailVerificationDescription)}</p>
                <div className="flex flex-col gap-4 mt-4">
                    <input
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="app-input"
                        type="text"
                        placeholder={t(TranslationKeys.emailVerificationCode)}
                    />

                    {uiError && <p className="flex bg-red-300 text-red-900 p-4 rounded-lg">{uiError}</p>}

                    <button
                        disabled={loading || !code || code.length < 6}
                        className="btn-main"
                        onClick={toNextStep}
                    >
                        {loading && <FaSpinner className="animate-spin"/>}
                        {t(TranslationKeys.continue)}
                    </button>
                </div>
            </Body>
        </>
    );
};

export default VerifyEmail;
